<template>
  <main class="main-content js-focus-hidden" id="MainContent" role="main" tabindex="-1">
    <div class="page_banner top_header_div load_header_div">
      <div class="container">
        <p class="subtitle">
          <a @click="logout()" id="customer_logout_link">Log out</a>
        </p>
        <h2 class="f-hl" style>
          <div
            class="f-hl-line animtiontitle"
            style="display: block; text-align: start; position: relative;"
          >My Account</div>
        </h2>
      </div>
    </div>
    <div class="section-bottom">
        <div class="left" v-if="userInfo.id">
            <h4>Orders History</h4>
            <table>
                <tr>
                    <th>Image</th>
                    <th>Order id</th>
                    <th>Price</th>
                    <th>Status</th>
                </tr>
                <div v-if="orders && orders.hits && orders.hits.length == 0" style="width: 100%;margin: 19% 1% 0 60%;">
                    No Orders Submitted yet
                </div>
                <tr v-else v-for="(items, ind) in orders" :key="ind">
                    <td style="width:50px; height:60px"><img :src="$store.state.file_url + items.cart[0].images[0].image[0].src + '?alt=media'" alt="" style="width:50px;object-fit:cover"></td>
                    <td>{{items._order_id}}</td>
                    <td v-if="currency == 'PKR'">{{currency}} {{items.total}}/-</td>
                    <td v-if="currency == 'USD'">{{currency}} {{convertCurrency(items.total)}}/-</td>
                    <td v-if="items.status == 'Pending'" style=""><span class="pending">{{items.status}}</span></td>
                    <td v-if="items.status == 'Verified'" style=""><span class="approve">{{items.status}}</span></td>
                    <td v-if="items.status == 'Cancelled'" style=""><span class="cancel">{{items.status}}</span></td>
                    <td v-if="items.status == 'Delivered'" style=""><span class="dilivered">{{items.status}}</span></td>
                </tr>
            
            </table>
        </div>
        <div class="right" v-if="!userInfo.id">
            <h4>Orders History</h4>
            <p>You Havent Placed any Order Yet</p>
        </div>
        <div class="right">
            <h4>Account Details</h4>
            <br>
            <a href="/#/account" class="f-bp">View Details <svg data-v-c4cfcb2e="" viewBox="0 0 6 10" class="f-bp-arrow"><path data-v-c4cfcb2e="" d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"></path></svg></a>
            <br>
            <br>
            <!-- <br>
            <h4 v-if="userInfo.size_chart">Edit your Size Chart</h4>
            <h4 v-else>Add your Size Chart</h4>
            <br>
            <a href="/#/sizeChart" v-if="userInfo.size_chart" class="f-bp">Edit <svg data-v-c4cfcb2e="" viewBox="0 0 6 10" class="f-bp-arrow"><path data-v-c4cfcb2e="" d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"></path></svg></a>
            <a href="/#/sizeChart" v-else class="f-bp">Add <svg data-v-c4cfcb2e="" viewBox="0 0 6 10" class="f-bp-arrow"><path data-v-c4cfcb2e="" d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"></path></svg></a> -->
        </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
export default {
  components: {
    Signin: () => import("@/components/Signin.vue"),
    Signup: () => import("@/components/Signup.vue"),
    loader: () => import("../components/loader.vue")
  },
  data() {
    return {
      userInfo: {},
      orders: [],
      error: false,
      currency: ""
    };
  },
  mounted() {
    this.init();
  },
  created() {
    setTimeout(() => {
      this.init();
      if (localStorage.getItem("currency")) {
        this.currency = JSON.parse(localStorage.getItem("currency"));
      }
    }, 500);
  },
  methods: {
    init() {
      this.$store.dispatch("Store");
      this.userInfo = this.$store.state.customer;
      this.orderRead();
    },
    logout(){
        localStorage.removeItem('users')
        this.$router.push({path:'/page/home'})
        location.reload()
    },
    userUpdate(e) {
      e.preventDefault();
      axios
        .post(this.$store.state.api + "users/_update/" + this.userInfo.id, {
          doc: this.userInfo
        })
        .then(response => {
          // console.log(response, "updateded");
          localStorage.setItem("users", JSON.stringify(this.userInfo));
          alert(done);
        });
    },
    orderRead() {
      this.$store
        .dispatch("OrderRead", {
          bool: {
            should: [],
            must: [
              {
                match_phrase: {
                  event: this.$store.state.event
                }
              },
              {
                match_phrase: {
                  "customer.email": this.userInfo.email
                }
              }
            ]
          }
        })
        .then(response => {
          this.orders = response;
          this.orders.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : ((a.createdAt > b.createdAt) ? -1 : 0));
        });
    },
    convertCurrency(amount) {
      var price = false;
      var c = false;
      if (localStorage.getItem("currencyValue")) {
        c = JSON.parse(localStorage.getItem("currencyValue"));
      }
      price = Math.round(amount / c);
      return price;
    }
  }
};
</script>

<style scoped>
.section-bottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
  justify-content: flex-start;
}
.left {
  width: 55%;
  min-width: 40%;
  padding: 5%;
}
.left h4{
    margin: 5% 0;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    font-family: "Saol Display";
    margin-bottom: .5rem;
    line-height: 1.1;
    color: inherit;
    margin-top: 0;
}
.right {
  width: 45%;
  min-width: 40%;
  padding: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.right h4{
    margin: 5% 0;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    font-family: "Saol Display";
    margin-bottom: .5rem;
    line-height: 1.1;
    color: inherit;
    margin-top: 0;
}
.f-bp{
    margin-top: 0%;
}
.main-content {
    position: relative;
    z-index: 1;
    background: #fff;
}
.page_banner {
    text-align: center;
    padding: 150px 0px 100px;
    background-color: #f7f8f6;
    min-height: 425px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page_middle {
    padding: 100px 0px;
    min-height: 500px;
}
.container {
    padding-right: 80px;
    padding-left: 80px;
}
.subtitle {
    font-size: 10px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 45px;
}
.animtiontitle {
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    font-family: "Saol Display";
    margin-bottom: .5rem;
    line-height: 1.1;
    color: inherit;
    margin-top: 0;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

@media only screen and (max-width: 580px) {
  .right, .left {
    width: 100%;
    min-width: 40%;
    padding: 10% 5%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .animtiontitle{
    text-align: center !important;
  }
}
</style>

