<template>
  <div class="contact-information mauto">
    <div class="Breadcrumb">
      <h2>Thank You For Submitting Order</h2>
      <p v-if="orderId">Your Order id {{orderId}}</p>
    </div>
    <form>
      <div class="section-content map">
        <div class="review-fieldset-body">
          <!-- <div class="map-block-body">
            <div class="review-block-inner">
              <div class="review-block-content"></div>
            </div>
          </div> -->
          <div class="map-block">
            <div class="map-block-inner">
              <div class="map-block-content">
                <h2>Your order is confirmed</h2>
                <p>You’ll receive an email when your order is ready.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-content">
        <div class="review-fieldset-body">
          <div class="map-block">
            <div class="map-block-inner">
              <div class="map-block-content">
                <h2>Customer information</h2>
              </div>
            </div>
            <div class="content-body">
              <div class="content-body-section">
                <div class="content-block-content">
                  <h2>Customer information</h2>
                  <p v-if="userInfo && userInfo.email">{{userInfo.email}}</p>
                </div>
                <div class="content-block-content">
                  <h2>Shipping address</h2>
                  <p
                    v-if="userInfo && userInfo.first_name"
                  >{{userInfo.first_name}} {{userInfo.last_name}}</p>
                  <p
                    v-if="userInfo && userInfo.phone"
                  >{{userInfo.phone}}</p>
                  <p
                    v-if="userInfo && userInfo.city"
                  >{{userInfo.city}}</p>
                  <p
                    v-if="userInfo && userInfo.country"
                  >{{userInfo.country}}</p>
                </div>
                <div class="content-block-content">
                  <h2>Shipping method</h2>
                  <p>COD</p>
                </div>
              </div>
              <div class="content-body-section">
                <div class="content-block-content">
                  <h2>Billing address</h2>
                  <p
                    v-if="userInfo && userInfo.first_name"
                  >{{userInfo.first_name}} {{userInfo.last_name}}</p>
                  <p
                    v-if="userInfo && userInfo.phone"
                  >{{userInfo.phone}}</p>
                  <p
                    v-if="userInfo && userInfo.city"
                  >{{userInfo.city}}</p>
                  <p
                    v-if="userInfo && userInfo.country"
                  >{{userInfo.country}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-btn">
        <p>
          Need help?
          <a
            href="/"
          >contact us</a>
        </p>
        <a
          href="/"
          class="btn"
        >Countinue Shopping</a>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data(){
    return{
      userInfo:{},
      orderId:''
    }
  },
  created(){
    this.$store.dispatch('Store')
    this.userInfo = this.$store.state.customer
    if(localStorage.getItem('orderid')){
        this.orderId = JSON.parse(localStorage.getItem('orderid'))
    }
  }
}
</script>

<style scoped>
.contact-information{
    margin-top: 15%;
    width: 80%;
    margin-bottom: 10%;
}
.header {
    display: none;
}

.home {
    /* background-image: linear-gradient(to right, white 60%, #fafafa 40%); */
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
}

.content .wrap {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 78.57rem;
    /* padding: 0 5%; */
    margin: 0 auto;
    flex: 1 0 auto;
}

.wrap .main {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    /* padding-left: 5%; */
    width: 60%;
    padding-right: 6%;
    float: left;
}

.wrap-content {
    padding: 0 5%;
    display: flex;
    width: 100%;
}

.main-header {
    padding-bottom: 16px;
}

.main-header a span {
    color: #333333;
    font-size: 28px;
}

.breadcrumb {
    display: flex;
    margin-top: 1em;
    padding: 0;
    list-style: none;
}

.breadcrumb li {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    /* font-weight: 500; */
}

.breadcrumb li.current {
    font-weight: 600;
}

.breadcrumb-item i {
    font-size: 16px;
    margin: 0 5px;
}

.Breadcrumb p {
    margin-bottom: 25px;
    font-size: 14px;
    margin-top: 5px;
}

.Breadcrumb h2 {
    margin-top: 30px;
    margin-bottom: 0;
    font-weight: 400;
    margin-bottom: 20px;
}

.main .main-content {
    padding-bottom: 50px;
    min-height: 68vh;
}

.main .section-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px;
    color: #333333;
}

.main .section-header h2 {
    font-size: 16px;
    margin: 0;
    font-weight: 400;
}

.main .section-header p {
    margin: 0;
    font-size: 13px;
}

.main .section-header p a {
    color: #af9aa4
}

.main .section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.fieldset {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.fieldset label {
    font-size: 12px;
}

input[type="checkbox"] {
    border: 1px solid #d9d9d9;
}

input:focus {
    outline: none;
    border: 2px solid #af9aa4 !important;
}

.checkbox {
    border: 1px solid #d9d9d9;
    margin-right: 10px;
    width: 15px;
    height: 15px;
}

.main .section-content .field {
    padding: 13px 10px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #d9d9d9;
    font-size: 12px;
}

.select-feild {
    width: 50% !important;
}

.main .section-content .field-half {
    padding: 13px 10px;
    border-radius: 5px;
    width: 46%;
    border: 1px solid #d9d9d9;
}

.main .section-content .field-half:nth-child(1) {
    margin-right: 5px;
}

.main .section-content .field-half:nth-child(2) {
    margin-left: 5px;
}

.section-shipping-address {
    padding-top: 3em;
    /* margin-bottom: 24px !important */
}

.section-shipping-addres {}

.main-footer {
    padding: 1em 0;
    border-top: 1px solid #e6e6e6;
}

.main-footer .copyright-text {
    font-size: 0.8571428571em;
    color: #737373;
}

.section__header {
    margin-bottom: 24px;
}

.shipping-address {
    padding-top: 50px;
}

.footer-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
}

.footer-btn a p {
    display: flex;
    font-size: 14px;
    color: #af9aa4;
}

.footer-btn p a {
    font-size: 14px;
    color: #af9aa4;
}

.footer-btn a p span {
    font-size: 20px;
}

.fotter-btn {
    float: right;
    margin-top: 0px;
}

.btn {
    background-color: #af9aa4;
    height: 0vw;
    color: #fff;
    font-weight: bold;
    font-size: 0.813vw;
    font-family: 'Aktiv Grotesk';
    position: relative;
    align-items: center;
    margin-top: 4.375vw;
    display: inline-flex;
    background-color: #ae97a2;
    padding: 0 3.125vw 0 4.688vw;
    line-height: 3.75vw;
    letter-spacing: 0.104vw;
    text-transform: uppercase;
    transition: background-color 0.4s ease-out;
}

.sidebar {
    width: 40%;
    padding-left: 4%;
    background-position: left top;
    float: right;
    background: #fafafa;
    border-left: 1px solid #e1e1e1;
    padding-top: 50px;
}

.sidebar-header {
    display: none;
}

.product-list {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
}

.product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.product-ls {
    display: flex;
    align-items: center;
}

.product-image {
    width: 80px;
}

.product-imgBox {
    width: 55px;
    height: 55px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1x solid;
}

.product-description {
    display: flex;
    flex-direction: column;
}

.product-description-name {
    font-size: 12px;
    font-weight: 700;
}

.product-description-varient {
    font-size: 12px;
    font-weight: 300;
}

.product-quantity {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(114, 114, 114, 0.9);
    text-align: center;
    font-size: 12px;
    color: #fff;
    position: absolute;
    margin-left: 47px;
    margin-top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-price {
    font-size: 14px;
    font-weight: 600;
}

.total-lines {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 25px;
}

.total-line-table {
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.total-line-subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-line__name {
    font-size: 13px;
}

.total-line__price {
    font-size: 14px;
    font-weight: 600;
}

.total-line__shipping {
    font-size: 12px;
}

.total-line-subtotal {
    padding-bottom: 5px;
}

.total-line {
    padding-top: 16px;
}

.total-price {
    font-size: 24px;
    font-weight: 500;
    color: #323232;
}

.user-image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    /* background: red; */
}

.user-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.user-info {
    margin-left: 12px;
}

.user-info p {
    margin: 0;
    font-size: 12px;
}

.user-info p a {
    margin: 0;
    color: #af9aa4;
}

.section-headerr {
    margin-bottom: 12px;
    color: #333333;
    padding-top: 3em;
}

.section-headerr h2 {
    margin-bottom: 12px;
    font-size: 18px;
    margin: 0;
    font-weight: 400;
}

.section-headerr p {
    font-size: 14px;
    margin-top: 5px;
}

.review-fieldset {
    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    padding: 0 15px;
    border-radius: 5px;
}

.review-block {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #d9d9d9;
}

.review-block:last-child {
    border-bottom: none
}

.review-block-inner {
    display: flex;
    align-items: center;
}

.review-block-label {
    width: 90px
}

.review-block-label,
.map-block-content,
.review-block-content {
    font-size: 14px;
}

.map {
    margin-bottom: 15px;
}

.review-block-switch {
    width: 35px;
}

.review-block-switch input {
    width: 20px;
    height: 20px;
}

.review-block-content span {
    font-weight: 700;
}

.map-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    /* border-bottom: 1px solid #d9d9d9; */
}

.map-block-content {
    padding-bottom: 10px;
    padding-left: 15px;
}

.map-block-content h2 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.map-block-content p {
    font-size: 14px;
    margin: 5px 0;
    color: #545454;
}

.map-block-body {
    width: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    color: #545454;
    justify-content: center;
    background: #fafafa;
}

.map-block-inner {
    display: flex;
    align-items: center;
}

.content-body {
    display: flex;
    width: 100%;
}

.content-body-section {
    width: 44%;
    padding: 10px 15px;
}

.content-block-content {
    margin-bottom: 20px;
}

.content-block-content h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.content-block-content p {
    font-size: 14px;
    margin: 5px 0;
}

.review-fieldset-body {
    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    /* padding: 0 15px;  */
    border-radius: 5px;
}

.review-fieldset-body .review-block {
    padding: 10px 15px;
}

.review-block-body {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    color: #545454;
    justify-content: center;
    background: #fafafa;
}

.review-block-body .review-block-content {
    width: 90%;
    text-align: center;
}

.review-block-body .review-block-content {
    width: 100%;
    padding: 10px;
}

.link--small {
    font-size: 12px;
    color: #af9aa4;
}

.link-small-free {
    font-size: 14px;
    color: #323232;
    font-weight: 600;
}

@media (max-width: 1224px) {
    .wrap {
        width: 90% !important;
    }
    .wrap-content {
        padding: 0;
    }
}

@media screen and (max-width: 999px) {
    .contact-information{
        margin-top: 30%;
    }
    .footer-btn a{
        /* margin-top: 10px; */
    }
    .header {
        display: block;
    }
    .header .wrap {
        width: 60% !important;
        margin: 0 auto !important;
        padding: 20px 0;
    }
    .header .wrap a {
        color: #333333;
        font-size: 22px;
    }
    .home {
        background: #fff;
    }
    .content .wrap {
        width: 100% !important;
    }
    .wrap-content {
        flex-direction: column-reverse;
    }
    .sidebar {
        width: 100%;
    }
    .wrap .main {
        padding-right: 0;
        margin: 0 auto;
    }
    .wrap {
        margin: 0 !important;
    }
    .sidebar {
        padding: 0;
        border: none;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
    }
    .sidebar-header {
        display: block;
    }
    .sidebar-content {
        display: none;
    }
    .sidebar-header {
        width: 60%;
        margin: 0 auto;
    }
    .accordians-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
    }
    .accordians-inner h4 {
        display: flex;
        color: #af9aa4;
        font-weight: 400;
        font-size: 14px;
    }
    .accordians-inner h4 span {
        padding-right: 10px;
        font-size: 22px;
    }
    .total-recap {
        font-size: 20px;
    }
    .main-header a {
        display: none;
    }
    .wrap .main {
        padding-top: 0px;
    }
    .main-header {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .header .wrap {
        width: 80% !important;
    }
    .sidebar-header {
        width: 80%;
    }
    .wrap-content .main {
        width: 80%;
    }
}

@media screen and (max-width: 568px) {
    .header .wrap {
        width: 90% !important;
    }
    .sidebar-header {
        width: 90%;
    }
    .wrap-content .main {
        width: 90%;
    }
    .content-body {
        flex-wrap: wrap;
    }
    .content-body-section{
        width: 97%;
        padding: 0px 15px;
    }
    .btn {
        height: 12.5vw;
        font-size: 2.778vw;
        margin-top: 11.111vw;
        line-height: 12.5vw;
        letter-spacing: 0.358vw;
        padding: 0 13.889vw 0 calc(100vw / 12 * 1);
    }
}

</style>