<template>
  <div class="doctor">
    <div class="heading">
    </div>
    <div class="section">
     <div class="inner2">
        <!-- <h5>Please pay with any method </h5> -->
        <div class="inner3">
            <h4 style="margin-top:20px">Please Wait While You are Being Redirected...</h4>
          <!-- <a class="btn" @click="$parent.initaiteCall({id:doc,amount:250})">Continue as Free</a> -->
            <!-- <a class="btn "  @click="cardPayment('MPAY')">Pay Via Credit / Debit Card</a>
            <a class="btn "  @click="cardPayment('MWALLET')">Pay Via Mobile Account (JAZZ CASH)</a>
            <a class="btn "  @click="cardPayment('OTC')" >Pay Via Voucher Payment (JAZZ CASH)</a> -->
            <!-- <a class="btn "  @click="cardPayment('DD')">Direct Debit</a> -->
        </div>
        <div class="forms" >
            <label id="salt" style="display:none;">t1z2sefwbc</label>
            <div class="formFielWrapper">
                <label class="">Hash values string: </label>
                <input type="text" id="hashValuesString" value="">
                <br><br>
            </div>
            <form name="jsform" method="post" action="https://sandbox.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform">
                <input type="hidden" name="pp_Version" value="1.1">
                <input id="cardType" type="hidden" name="pp_TxnType" value="MPAY">
                <input type="hidden" name="pp_Language" value="EN">
                <input type="hidden" name="pp_MerchantID" value="MC18259">
                <input type="hidden" name="pp_SubMerchantID" value="">
                <input type="hidden" name="pp_Password" value="1hv2031b50">
                <input type="hidden" name="pp_BankID" value="TBANK">
                <input type="hidden" name="pp_ProductID" value="RETL">

                <div class="formFielWrapper">
                    <label class="active">pp_TxnRefNo: </label>
                    <input type="text" name="pp_TxnRefNo" id="pp_TxnRefNo" value="T20200531023704">
                </div>

                <div class="formFielWrapper">
                    <label class="active">pp_Amount: </label>
                    <input type="text" id="ppAmount" name="pp_Amount" :value="totalPayment()">
                </div>

                <input type="hidden" name="pp_TxnCurrency" :value="$store.state.currency">
                <input id="txDate" type="hidden" name="pp_TxnDateTime" value="2020 06 01 02 37 04">
                <div class="formFielWrapper">
                    <label class="active">pp_BillReference: </label>
                    <input type="text" name="pp_BillReference" value="billRef">
                </div>

                <div class="formFielWrapper">
                    <label class="active">pp_Description: </label>
                    <input type="text" name="pp_Description" value="Description of transaction">
                </div>

                <input  id="txExpiry" type="hidden" name="pp_TxnExpiryDateTime" value="2020 06 10 02 37 04">

                <div class="formFielWrapper">
                    <label class="">pp_ReturnURL: </label>
                    <input type="text" name="pp_ReturnURL" value="https://worldofhsy.herokuapp.com/#/checkout/submitOrderCheckout">
                </div>

                <input type="hidden" name="pp_SecureHash" value="">
                <input type="hidden" name="ppmpf_1" value="1">
                <input type="hidden" name="ppmpf_2" value="2">
                <input type="hidden" name="ppmpf_3" value="3">
                <input type="hidden" name="ppmpf_4" value="4">
                <input type="hidden" name="ppmpf_5" value="5">
                <button type="button" onclick="submitForm()">Submit</button>
            </form>
        </div>       
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios"
import moment from "moment";
import CryptoJS from 'crypto-js';
export default {
  name: 'Payment',
   data() {
        return {
            patient: {},
            result:false,
            doc:false,
            callNow:false,
            balance:0,
            fee:0,
            userInfo: {},
            data: {}
        }
    },
    methods:{
        CalculateHash(){
            var IntegritySalt = document.getElementById("salt").innerText;
            var  hashString = '';
            hashString += IntegritySalt + '&';
            if (document.getElementsByName("pp_Amount")[0].value != '') {
                hashString += document.getElementsByName("pp_Amount")[0].value + '&';
            }
            if (document.getElementsByName("pp_BankID")[0].value != '') {
                hashString += document.getElementsByName("pp_BankID")[0].value + '&';
            }
            if (document.getElementsByName("pp_BillReference")[0].value != '') {
                hashString += document.getElementsByName("pp_BillReference")[0].value + '&';
            }
            if (document.getElementsByName("pp_Description")[0].value != '') {
                hashString += document.getElementsByName("pp_Description")[0].value + '&';
            }
            if (document.getElementsByName("pp_Language")[0].value != '') {
                hashString += document.getElementsByName("pp_Language")[0].value + '&';
            }
            if (document.getElementsByName("pp_MerchantID")[0].value != '') {
                hashString += document.getElementsByName("pp_MerchantID")[0].value + '&';
            }
            if (document.getElementsByName("pp_Password")[0].value != '') {
                hashString += document.getElementsByName("pp_Password")[0].value + '&';
            }
            if (document.getElementsByName("pp_ProductID")[0].value != '') {
                hashString += document.getElementsByName("pp_ProductID")[0].value + '&';
            }
            if (document.getElementsByName("pp_ReturnURL")[0].value != '') {
                hashString += document.getElementsByName("pp_ReturnURL")[0].value + '&';
            }
            if (document.getElementsByName("pp_SubMerchantID")[0].value != '') {
                hashString += document.getElementsByName("pp_SubMerchantID")[0].value + '&';
            }
            if (document.getElementsByName("pp_TxnCurrency")[0].value != '') {
                hashString += document.getElementsByName("pp_TxnCurrency")[0].value + '&';
            }
            if (document.getElementsByName("pp_TxnDateTime")[0].value != '') {
                hashString += document.getElementsByName("pp_TxnDateTime")[0].value + '&';
            }
            if (document.getElementsByName("pp_TxnExpiryDateTime")[0].value != '') {
                hashString += document.getElementsByName("pp_TxnExpiryDateTime")[0].value + '&';
            }
            if (document.getElementsByName("pp_TxnRefNo")[0].value != '') {
                hashString += document.getElementsByName("pp_TxnRefNo")[0].value + '&';
            }
            if (document.getElementsByName("pp_TxnType")[0].value != '') {
                hashString += document.getElementsByName("pp_TxnType")[0].value + '&';
            }
            if (document.getElementsByName("pp_Version")[0].value != '') {
                hashString += document.getElementsByName("pp_Version")[0].value + '&';
            }
            if (document.getElementsByName("ppmpf_1")[0].value != '') {
                hashString += document.getElementsByName("ppmpf_1")[0].value + '&';
            }
            if (document.getElementsByName("ppmpf_2")[0].value != '') {
                hashString += document.getElementsByName("ppmpf_2")[0].value + '&';
            }
            if (document.getElementsByName("ppmpf_3")[0].value != '') {
                hashString += document.getElementsByName("ppmpf_3")[0].value + '&';
            }
            if (document.getElementsByName("ppmpf_4")[0].value != '') {
                hashString += document.getElementsByName("ppmpf_4")[0].value + '&';
            }
            if (document.getElementsByName("ppmpf_5")[0].value != '') {
                hashString += document.getElementsByName("ppmpf_5")[0].value + '&';
            }
            hashString = hashString.slice(0, -1);
            document.getElementById("hashValuesString").value = hashString;
        },
        randomString(length, chars) {
            var mask = '';
            if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
            if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (chars.indexOf('#') > -1) mask += '0123456789';
            if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
            var result = '';
            for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
            return result;
        },
        cardPayment(type){
            var userName  = this.userInfo.first_name;
            // console.log(userName, type)
            userName = userName.split(' ');
            var named = userName[0].charAt(0).toUpperCase();
            if(userName.length >1){
              named += userName[1].charAt(0).toUpperCase();
            }
            var trnaId = named+this.randomString(14,'#aA')
            axios
            .post(this.$store.state.api+"payment/_doc/",{
                name: this.userInfo.first_name,
                patient_id: this.userInfo.id,
                type: type,
                status:' pending',
                transcationCode : trnaId,
                event: "hsy",
            })
            .then(response => {
                localStorage.setItem('trx',response.data._id);
                localStorage.setItem('docx',this.$route.query.doc);
                document.getElementById('pp_TxnRefNo').value = trnaId;
                // console.log(moment().add(1,'days').format('YYYYMMDDHHmmss'))
                document.getElementById('txExpiry').value = moment().add(1,'days').format('YYYYMMDDHHmmss');
                document.getElementById('txDate').value = moment().format('YYYYMMDDHHmmss');
                document.getElementById('cardType').value = type
                this.CalculateHash();
                var IntegritySalt = document.getElementById("salt").innerText;
                var hash = CryptoJS.HmacSHA256(document.getElementById("hashValuesString").value, IntegritySalt);
                document.getElementsByName("pp_SecureHash")[0].value = hash + '';
                // console.log('hash: ' + document.getElementsByName("pp_SecureHash")[0].value);
                document.jsform.submit();
            })
        },
        totalPayment(){
            // console.log(parseInt(this.$store.state.cart.calculate.total) * 100)
            return  parseInt(this.$store.state.cart.calculate.total) * 100
        }
    },
  created() {
    this.userInfo = this.$store.state.customer
    this.data = this.$store.state.cart
    this.cardPayment('MPAY')
    if(document.getElementById('ppAmount')){
        // document.getElementById('ppAmount').value = 
        // console.log(parseInt(this.$store.state.cart.calculate.total) * 100)
    }
    // this.$store.dispatch('patientBalance',this.$store.state.patient).then(r=>{
    // console.log(r)
    //     this.balance = r;
    // })
    // this.doc = this.$route.query.doc;
    // this.patient = this.$store.state.patient;
    // console.log(this.$route)
    // this.$store.dispatch('doctors',{id:this.$route.query.doc})
    // .then(r=>{
    //     this.fee = parseInt(r.fee);
    //     document.getElementById('ppAmount').value = parseInt(this.fee) * 100
    // })
    // if(this.$route.query.return && localStorage.getItem('trx')){
    //         axios
    //         .get(
    //         "https://search-shopeng-api-es-3f2agislm562rmbxy56ewaedpi.eu-central-1.es.amazonaws.com/payment/_doc/"+localStorage.getItem('trx')
    //         )
    //         .then(response => {
    //             localStorage.removeItem('trx')
    //                 if(response.data._source.status == 'Approved'){
    //                     this.callNow = 'Thanks for the payment.<br> Now you can click Call Now.';
    //                     //this.$parent.initaiteCall({id:localStorage.getItem('docx')})
    //                     //this.$router.push({path:'/account'});
    //                     //this.callNow = true;
    //                     this.doc = localStorage.getItem('docx')
    //                 }else{
    //                     this.result = 'Error: '+response.data._source.description.pp_ResponseMessage
    //                 }
    //         })
    //     }
    }
}
</script>

<style scoped>

p{
  width: 100%;
}
.doctor .section .inner3 {
   margin-top: 0%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.doctor .section .inner3 button{
  margin: 10px;
}
.doctor .section .inner3 .disabled{
  opacity: 0.3;
}
.btn {
    max-width: 20%;
    border: 1px solid #ddd;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 10px;
    background-color: #087bb2 !important;
    color: #fff !important;
    border: none;
    border-radius: 18px;
    margin: 10px;
    min-width: 245px;
}

select{
    height: 38px;
    border-bottom: 1px solid #dadada;
    margin-top: 17px;
    color: #000;
    padding-left: 0px;
    width: 43%;
    background: none;
    outline: none;
    border: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    font-size: 13px;
    margin-left: 2%;
}
.inner2 form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}
.doctor .section .inner3 a {
    padding: 14px 4%;
    
}
.doctor .section .inner2 input {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 43%;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 2% 0px;
    font-size: 14px;
    margin-left: 2%;
}
.forms{
    position: fixed;
    left: -5000px;
}
</style>