<template>
  <div class="contact-information">
    <form v-on:submit="shipping()">
      <div class="contact-info" v-if="empty == false">
        <div class="section-header">
          <h2>Contact information</h2>
        </div>
        <div class="section-content">
          <div class="fieldset" v-if="userInfo">
            <div class="user-image" v-if="userInfo && userInfo.id">
              <img src="../assets/user.png" alt />
            </div>
            <div class="user-info">
              <p
                v-if="userInfo && userInfo.id"
              >{{userInfo.first_name}} {{userInfo.last_name}}</p>
              <p
                v-if="userInfo && userInfo.id"
              >Customer Id: {{userInfo.id}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-info">
        <!-- <div class="section-header">
          <h2 v-if="userInfo.id">Contact information</h2>
          <p >
            Already have an account?
            <a href="https://anfords-pakistan.myshopify.com/account/login">Log in</a>
          </p>
        </div> -->
        <div class="section-content">
          <div class="fieldset">
            <input
              type="number"
              class="field field-half"
              placeholder="Phone No"
              v-model="userInfo.phone"
              required
              style="width:100%"
            />
            <input
              type="email"
              class="field field-half"
              placeholder="Email Address"
              v-model="userInfo.email"
              required
              style="width:100%"
            />
          </div>
          <div class="fieldset">
            <input type="checkbox" class="checkbox" v-model="userInfo.keep_update"/><label
            >Keep me up to date on news and exclusive offers</label
            >
          </div>
        </div>
      </div>
      <div class="section-shipping-address">
        <div class="section-header">
          <h2>Shipping address</h2>
        </div>
        <div class="section-content">
          <div class="fieldset">
            <input
              type="text"
              class="field field-half"
              placeholder="First Name"
              v-model="userInfo.first_name"
              required
            />
            <input
              type="text"
              class="field field-half"
              placeholder="Last Name"
              v-model="userInfo.last_name"
              required
            />
          </div>
          <div class="fieldset">
            <input
              type="text"
              class="field"
              placeholder="Address"
              required
              v-model="userInfo.address1" 
            />
          </div>
          <div class="fieldset">
            <input
              type="text"
              class="field"
              placeholder="Apartment, suite, etc. (optional)"
              v-model="userInfo.address2" 
            />
          </div>
          <div class="fieldset">
            <select v-model="userInfo.city" required name="Location" id="Location" class="field">
              <option value disabled>Select The City</option>
              <option value="Islamabad">Islamabad</option>
              <option value disabled>Punjab Cities</option>
              <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
              <option value="Ahmadpur East">Ahmadpur East</option>
              <option value="Ali Khan Abad">Ali Khan Abad</option>
              <option value="Alipur">Alipur</option>
              <option value="Arifwala">Arifwala</option>
              <option value="Attock">Attock</option>
              <option value="Bhera">Bhera</option>
              <option value="Bhalwal">Bhalwal</option>
              <option value="Bahawalnagar">Bahawalnagar</option>
              <option value="Bahawalpur">Bahawalpur</option>
              <option value="Bhakkar">Bhakkar</option>
              <option value="Burewala">Burewala</option>
              <option value="Chillianwala">Chillianwala</option>
              <option value="Chakwal">Chakwal</option>
              <option value="Chichawatni">Chichawatni</option>
              <option value="Chiniot">Chiniot</option>
              <option value="Chishtian">Chishtian</option>
              <option value="Daska">Daska</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
              <option value="Dhaular">Dhaular</option>
              <option value="Dina">Dina</option>
              <option value="Dinga">Dinga</option>
              <option value="Dipalpur">Dipalpur</option>
              <option value="Faisalabad">Faisalabad</option>
              <option value="Fateh Jhang">Fateh Jang</option>
              <option value="Ghakhar Mandi">Ghakhar Mandi</option>
              <option value="Gojra">Gojra</option>
              <option value="Gujranwala">Gujranwala</option>
              <option value="Gujrat">Gujrat</option>
              <option value="Gujar Khan">Gujar Khan</option>
              <option value="Hafizabad">Hafizabad</option>
              <option value="Haroonabad">Haroonabad</option>
              <option value="Hasilpur">Hasilpur</option>
              <option value="Haveli">Haveli</option>
              <option value="Lakha">Lakha</option>
              <option value="Jalalpur">Jalalpur</option>
              <option value="Jattan">Jattan</option>
              <option value="Jampur">Jampur</option>
              <option value="Jaranwala">Jaranwala</option>
              <option value="Jhang">Jhang</option>
              <option value="Jhelum">Jhelum</option>
              <option value="Kalabagh">Kalabagh</option>
              <option value="Karor Lal Esan">Karor Lal Esan</option>
              <option value="Kasur">Kasur</option>
              <option value="Kamalia">Kamalia</option>
              <option value="Kamoke">Kamoke</option>
              <option value="Khanewal">Khanewal</option>
              <option value="Khanpur">Khanpur</option>
              <option value="Kharian">Kharian</option>
              <option value="Khushab">Khushab</option>
              <option value="Kot Adu">Kot Adu</option>
              <option value="Jauharabad">Jauharabad</option>
              <option value="Lahore">Lahore</option>
              <option value="Lalamusa">Lalamusa</option>
              <option value="Layyah">Layyah</option>
              <option value="Liaquat Pur">Liaquat Pur</option>
              <option value="Lodhran">Lodhran</option>
              <option value="Malakwal">Malakwal</option>
              <option value="Mamoori">Mamoori</option>
              <option value="Mailsi">Mailsi</option>
              <option value="Mandi Bahauddin">Mandi Bahauddin</option>
              <option value="mian Channu">Mian Channu</option>
              <option value="Mianwali">Mianwali</option>
              <option value="Multan">Multan</option>
              <option value="Murree">Murree</option>
              <option value="Muridke">Muridke</option>
              <option value="Mianwali Bangla">Mianwali Bangla</option>
              <option value="Muzaffargarh">Muzaffargarh</option>
              <option value="Narowal">Narowal</option>
              <option value="Okara">Okara</option>
              <option value="Renala Khurd">Renala Khurd</option>
              <option value="Pakpattan">Pakpattan</option>
              <option value="Pattoki">Pattoki</option>
              <option value="Pir Mahal">Pir Mahal</option>
              <option value="Qaimpur">Qaimpur</option>
              <option value="Qila Didar Singh">Qila Didar Singh</option>
              <option value="Rabwah">Rabwah</option>
              <option value="Raiwind">Raiwind</option>
              <option value="Rajanpur">Rajanpur</option>
              <option value="Rahim Yar Khan">Rahim Yar Khan</option>
              <option value="Rawalpindi">Rawalpindi</option>
              <option value="Sadiqabad">Sadiqabad</option>
              <option value="Safdarabad">Safdarabad</option>
              <option value="Sahiwal">Sahiwal</option>
              <option value="Sangla Hill">Sangla Hill</option>
              <option value="Sarai Alamgir">Sarai Alamgir</option>
              <option value="Sargodha">Sargodha</option>
              <option value="Shakargarh">Shakargarh</option>
              <option value="Sheikhupura">Sheikhupura</option>
              <option value="Sialkot">Sialkot</option>
              <option value="Sohawa">Sohawa</option>
              <option value="Soianwala">Soianwala</option>
              <option value="Siranwali">Siranwali</option>
              <option value="Talagang">Talagang</option>
              <option value="Taxila">Taxila</option>
              <option value="Toba Tek  Singh">Toba Tek Singh</option>
              <option value="Vehari">Vehari</option>
              <option value="Wah Cantonment">Wah Cantonment</option>
              <option value="Wazirabad">Wazirabad</option>
              <option value disabled>Sindh Cities</option>
              <option value="Badin">Badin</option>
              <option value="Bhirkan">Bhirkan</option>
              <option value="Rajo Khanani">Rajo Khanani</option>
              <option value="Chak">Chak</option>
              <option value="Dadu">Dadu</option>
              <option value="Digri">Digri</option>
              <option value="Diplo">Diplo</option>
              <option value="Dokri">Dokri</option>
              <option value="Ghotki">Ghotki</option>
              <option value="Haala">Haala</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Islamkot">Islamkot</option>
              <option value="Jacobabad">Jacobabad</option>
              <option value="Jamshoro">Jamshoro</option>
              <option value="Jungshahi">Jungshahi</option>
              <option value="Kandhkot">Kandhkot</option>
              <option value="Kandiaro">Kandiaro</option>
              <option value="Karachi">Karachi</option>
              <option value="Kashmore">Kashmore</option>
              <option value="Keti Bandar">Keti Bandar</option>
              <option value="Khairpur">Khairpur</option>
              <option value="Kotri">Kotri</option>
              <option value="Larkana">Larkana</option>
              <option value="Matiari">Matiari</option>
              <option value="Mehar">Mehar</option>
              <option value="Mirpur Khas">Mirpur Khas</option>
              <option value="Mithani">Mithani</option>
              <option value="Mithi">Mithi</option>
              <option value="Mehrabpur">Mehrabpur</option>
              <option value="Moro">Moro</option>
              <option value="Nagarparkar">Nagarparkar</option>
              <option value="Naudero">Naudero</option>
              <option value="Naushahro Feroze">Naushahro Feroze</option>
              <option value="Naushara">Naushara</option>
              <option value="Nawabshah">Nawabshah</option>
              <option value="Nazimabad">Nazimabad</option>
              <option value="Qambar">Qambar</option>
              <option value="Qasimabad">Qasimabad</option>
              <option value="Ranipur">Ranipur</option>
              <option value="Ratodero">Ratodero</option>
              <option value="Rohri">Rohri</option>
              <option value="Sakrand">Sakrand</option>
              <option value="Sanghar">Sanghar</option>
              <option value="Shahbandar">Shahbandar</option>
              <option value="Shahdadkot">Shahdadkot</option>
              <option value="Shahdadpur">Shahdadpur</option>
              <option value="Shahpur Chakar">Shahpur Chakar</option>
              <option value="Shikarpaur">Shikarpaur</option>
              <option value="Sukkur">Sukkur</option>
              <option value="Tangwani">Tangwani</option>
              <option value="Tando Adam Khan">Tando Adam Khan</option>
              <option value="Tando Allahyar">Tando Allahyar</option>
              <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
              <option value="Thatta">Thatta</option>
              <option value="Umerkot">Umerkot</option>
              <option value="Warah">Warah</option>
              <option value disabled>Khyber Cities</option>
              <option value="Abbottabad">Abbottabad</option>
              <option value="Adezai">Adezai</option>
              <option value="Alpuri">Alpuri</option>
              <option value="Akora Khattak">Akora Khattak</option>
              <option value="Ayubia">Ayubia</option>
              <option value="Banda Daud Shah">Banda Daud Shah</option>
              <option value="Bannu">Bannu</option>
              <option value="Batkhela">Batkhela</option>
              <option value="Battagram">Battagram</option>
              <option value="Birote">Birote</option>
              <option value="Chakdara">Chakdara</option>
              <option value="Charsadda">Charsadda</option>
              <option value="Chitral">Chitral</option>
              <option value="Daggar">Daggar</option>
              <option value="Dargai">Dargai</option>
              <option value="Darya Khan">Darya Khan</option>
              <option value="dera Ismail Khan">Dera Ismail Khan</option>
              <option value="Doaba">Doaba</option>
              <option value="Dir">Dir</option>
              <option value="Drosh">Drosh</option>
              <option value="Hangu">Hangu</option>
              <option value="Haripur">Haripur</option>
              <option value="Karak">Karak</option>
              <option value="Kohat">Kohat</option>
              <option value="Kulachi">Kulachi</option>
              <option value="Lakki Marwat">Lakki Marwat</option>
              <option value="Latamber">Latamber</option>
              <option value="Madyan">Madyan</option>
              <option value="Mansehra">Mansehra</option>
              <option value="Mardan">Mardan</option>
              <option value="Mastuj">Mastuj</option>
              <option value="Mingora">Mingora</option>
              <option value="Nowshera">Nowshera</option>
              <option value="Paharpur">Paharpur</option>
              <option value="Pabbi">Pabbi</option>
              <option value="Peshawar">Peshawar</option>
              <option value="Saidu Sharif">Saidu Sharif</option>
              <option value="Shorkot">Shorkot</option>
              <option value="Shewa Adda">Shewa Adda</option>
              <option value="Swabi">Swabi</option>
              <option value="Swat">Swat</option>
              <option value="Tangi">Tangi</option>
              <option value="Tank">Tank</option>
              <option value="Thall">Thall</option>
              <option value="Timergara">Timergara</option>
              <option value="Tordher">Tordher</option>
              <option value disabled>Balochistan Cities</option>
              <option value="Awaran">Awaran</option>
              <option value="Barkhan">Barkhan</option>
              <option value="Chagai">Chagai</option>
              <option value="Dera Bugti">Dera Bugti</option>
              <option value="Gwadar">Gwadar</option>
              <option value="Harnai">Harnai</option>
              <option value="Jafarabad">Jafarabad</option>
              <option value="Jhal Magsi">Jhal Magsi</option>
              <option value="Kacchi">Kacchi</option>
              <option value="Kalat">Kalat</option>
              <option value="Kech">Kech</option>
              <option value="Kharan">Kharan</option>
              <option value="Khuzdar">Khuzdar</option>
              <option value="Killa Abdullah">Killa Abdullah</option>
              <option value="Killa Saifullah">Killa Saifullah</option>
              <option value="Kohlu">Kohlu</option>
              <option value="Lasbela">Lasbela</option>
              <option value="Lehri">Lehri</option>
              <option value="Loralai">Loralai</option>
              <option value="Mastung">Mastung</option>
              <option value="Musakhel">Musakhel</option>
              <option value="Nasirabad">Nasirabad</option>
              <option value="Nushki">Nushki</option>
              <option value="Panjgur">Panjgur</option>
              <option value="Pishin valley">Pishin Valley</option>
              <option value="Quetta">Quetta</option>
              <option value="Sherani">Sherani</option>
              <option value="Sibi">Sibi</option>
              <option value="Sohbatpur">Sohbatpur</option>
              <option value="Washuk">Washuk</option>
              <option value="Zhob">Zhob</option>
              <option value="Ziarat">Ziarat</option>
              <option value="Ziarat">Ziarat</option>
              <option value="Azad Jammu & Kashmir">Azad Jammu & Kashmir</option>
              <option value="Gilgit Baltistan">Gilgit Baltistan</option>
            </select>
          </div>
          <div class="fieldset">
            <select v-model="userInfo.country" required class="field field-half select-feild">
                <option value selected disabled>Select any Country</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">Russian Federation</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                      <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                      <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">United Arab Emirates</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">Virgin Islands, British</option>
                      <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                      <option value="Wallis and Futuna">Wallis and Futuna</option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
            </select>
            <input
              type="text"
              class="field field-half select-feild"
              placeholder="Province" required
              v-model="userInfo.province"
            />
            <!-- <select v-model="userInfo.province" required class="field field-half select-feild">
              <option value selected disabled>Select any Province</option>
              <option value="Punjab">Punjab</option>
              <option value="Sindh">Sindh</option>
              <option value="Balochistan">Balochistan</option>
              <option value="KPK">KPK</option>
              <option value="Gilgit Baltistan">Gilgit Baltistan</option>
            </select> -->
            <input
              style="margin-left:2%"
              type="text"
              class="field field-half"
              placeholder="Postal Code"
              v-model="userInfo.zip"
            />
          </div>
        </div>
      </div>
      <div class="fotter-btn">
        <button class="btn" type="submit">Continue to shipping</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { json } from 'body-parser';
export default {
  components:{
  },
  data() {
    return {
      userInfo: {},
      empty: false,
      error: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    //   this.$store.dispatch('Store')
    //   this.userInfo = this.$store.state.customer
      this.userInfo.city = 'Lahore'
      this.userInfo.country = 'Pakistan'
      this.userInfo.province = 'Punjab'
      console.log('users', this.userInfo)
    },
    shipping() {
      event.preventDefault();
      console.log('users', this.userInfo)
      localStorage.setItem('users', JSON.stringify(this.userInfo))
      this.$router.push({ path: "/checkout/shipping" });
    },
    contctInfo() {
      this.$router.push({ path: "/" });
    },
    paymentck() {
      this.$router.push({ path: "/payment" });
    },
  }
};
</script>

<style>
.header {
    display: none;
}

.home {
    /* background-image: linear-gradient(to right, white 60%, #fafafa 40%); */
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
}

.content .wrap {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 78.57rem;
    /* padding: 0 5%; */
    margin: 0 auto;
    flex: 1 0 auto;
}

.wrap .main {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    /* padding-left: 5%; */
    width: 60%;
    padding-right: 6%;
    float: left;
}

.wrap-content {
    padding: 0 5%;
    display: flex;
    width: 100%;
}

.main-header {
    padding-bottom: 16px;
}

.main-header a span {
    color: #333333;
    font-size: 28px;
}

.breadcrumb {
    display: flex;
    margin-top: 1em;
    padding: 0;
    list-style: none;
}

.breadcrumb li {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    /* font-weight: 500; */
}

.breadcrumb li.current {
    font-weight: 600;
}

.breadcrumb-item i {
    font-size: 16px;
    margin: 0 5px;
}

.Breadcrumb p {
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 25px;
}

.Breadcrumb h2 {
    margin-top: 0;
    font-weight: 400;
}

.main .main-content {
    padding-bottom: 50px;
    min-height: 68vh;
}

.main .section-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px;
    color: #333333;
}

.main .section-header h2 {
    font-size: 16px;
    margin: 0;
    font-weight: 400;
}

.main .section-header p {
    margin: 0;
    font-size: 13px;
}

.main .section-header p a {
    color: #197bbd
}

.main .section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.fieldset {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.fieldset label {
    font-size: 12px;
}

input[type="checkbox"] {
    border: 1px solid #d9d9d9;
}

input:focus {
    outline: none;
    border: 2px solid #197bbd !important;
}

.checkbox {
    border: 1px solid #d9d9d9;
    margin-right: 10px;
    width: 15px;
    height: 15px;
}

.main .section-content .field {
    padding: 13px 10px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #d9d9d9;
    font-size: 12px;
}

.select-feild {
    width: 50% !important;
}

.main .section-content .field-half {
    padding: 13px 10px;
    border-radius: 5px;
    width: 49%;
    border: 1px solid #d9d9d9;
}

.main .section-content .field-half:nth-child(1) {
    margin-right: 5px;
}

.main .section-content .field-half:nth-child(2) {
    margin-left: 5px;
}

.section-shipping-address {
    padding-top: 3em;
    /* margin-bottom: 24px !important */
}

.section-shipping-addres {}

.main-footer {
    padding: 1em 0;
    border-top: 1px solid #e6e6e6;
}

.main-footer .copyright-text {
    font-size: 0.8571428571em;
    color: #737373;
}

.section__header {
    margin-bottom: 24px;
}

.shipping-address {
    padding-top: 50px;
}

.footer-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}

.footer-btn a p {
    display: flex;
    font-size: 14px;
    color: #197bbd;
}

.footer-btn p a {
    font-size: 14px;
    color: #197bbd;
}

.footer-btn a p span {
    font-size: 20px;
}

.fotter-btn {
    float: right;
    margin-top: 40px;
}

.btn {
    height: 3.75vw;
    color: #fff;
    font-weight: bold;
    font-size: 0.813vw;
    font-family: 'Aktiv Grotesk';
    position: relative;
    align-items: center;
    margin-top: 0vw;
    display: inline-flex;
    background-color: #ae97a2;
    padding: 0 3.125vw 0 4.688vw;
    line-height: 3.75vw;
    letter-spacing: 0.104vw;
    text-transform: uppercase;
    transition: background-color 0.4s ease-out;
}

.sidebar {
    width: 40%;
    padding-left: 4%;
    background-position: left top;
    float: right;
    background: #fafafa;
    border-left: 1px solid #e1e1e1;
    padding-top: 50px;
}

.sidebar-header {
    display: none;
}

.product-list {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
}

.product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.product-ls {
    display: flex;
    align-items: center;
}

.product-image {
    width: 80px;
}

.product-imgBox {
    width: 55px;
    height: 55px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1x solid;
}

.product-description {
    display: flex;
    flex-direction: column;
}

.product-description-name {
    font-size: 12px;
    font-weight: 700;
}

.product-description-varient {
    font-size: 12px;
    font-weight: 300;
}

.product-quantity {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(114, 114, 114, 0.9);
    text-align: center;
    font-size: 12px;
    color: #fff;
    position: absolute;
    margin-left: 47px;
    margin-top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-price {
    font-size: 14px;
    font-weight: 600;
}

.total-lines {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 25px;
}

.total-line-table {
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.total-line-subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-line__name {
    font-size: 13px;
}

.total-line__price {
    font-size: 14px;
    font-weight: 600;
}

.total-line__shipping {
    font-size: 12px;
}

.total-line-subtotal {
    padding-bottom: 5px;
}

.total-line {
    padding-top: 16px;
}

.total-price {
    font-size: 24px;
    font-weight: 500;
    color: #323232;
}

.user-image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    /* background: red; */
}

.user-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.user-info {
    margin-left: 12px;
}

.user-info p {
    margin: 0;
    font-size: 12px;
}

.user-info p a {
    margin: 0;
    color: #197bbd;
}

.section-headerr {
    margin-bottom: 12px;
    color: #333333;
    padding-top: 3em;
}

.section-headerr h2 {
    margin-bottom: 12px;
    font-size: 18px;
    margin: 0;
    font-weight: 400;
}

.section-headerr p {
    font-size: 14px;
    margin-top: 5px;
}

.review-fieldset {
    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    padding: 0 15px;
    border-radius: 5px;
}

.review-block {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #d9d9d9;
}

.review-block:last-child {
    border-bottom: none
}

.review-block-inner {
    display: flex;
    align-items: center;
}

.review-block-label {
    width: 90px
}

.review-block-label,
.map-block-content,
.review-block-content {
    font-size: 14px;
}

.map {
    margin-bottom: 15px;
}

.review-block-switch {
    width: 35px;
}

.review-block-switch input {
    width: 20px;
    height: 20px;
}

.review-block-content span {
    font-weight: 700;
}

.map-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    /* border-bottom: 1px solid #d9d9d9; */
}

.map-block-content {
    padding-bottom: 10px;
    padding-left: 15px;
}

.map-block-content h2 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.map-block-content p {
    font-size: 14px;
    margin: 5px 0;
    color: #545454;
}

.map-block-body {
    width: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    color: #545454;
    justify-content: center;
    background: #fafafa;
}

.map-block-inner {
    display: flex;
    align-items: center;
}

.content-body {
    display: flex;
    width: 100%;
}

.content-body-section {
    width: 44%;
    padding: 10px 15px;
}

.content-block-content {
    margin-bottom: 20px;
}

.content-block-content h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.content-block-content p {
    font-size: 14px;
    margin: 5px 0;
}

.review-fieldset-body {
    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    /* padding: 0 15px;  */
    border-radius: 5px;
}

.review-fieldset-body .review-block {
    padding: 10px 15px;
}

.review-block-body {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    color: #545454;
    justify-content: center;
    background: #fafafa;
}

.review-block-body .review-block-content {
    width: 90%;
    text-align: center;
}

.review-block-body .review-block-content {
    width: 100%;
    padding: 10px;
}

.link--small {
    font-size: 12px;
    color: #197bbd;
}

.link-small-free {
    font-size: 14px;
    color: #323232;
    font-weight: 600;
}

@media (max-width: 1224px) {
    .wrap {
        width: 90% !important;
    }
    .wrap-content {
        padding: 0;
    }
}

@media screen and (max-width: 999px) {
    .header {
        display: block;
    }
    .header .wrap {
        width: 60% !important;
        margin: 0 auto !important;
        padding: 20px 0;
    }
    .header .wrap a {
        color: #333333;
        font-size: 22px;
    }
    .home {
        /* background: #fff; */
    }
    .content .wrap {
        width: 100% !important;
    }
    .wrap-content {
        flex-direction: column-reverse;
    }
    .sidebar {
        width: 100%;
    }
    .wrap .main {
        padding-right: 0;
        margin: 0 auto;
    }
    .wrap {
        margin: 0 !important;
    }
    .sidebar {
        padding: 0;
        border: none;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
    }
    .sidebar-header {
        display: block;
    }
    .sidebar-content {
        display: none;
    }
    .sidebar-header {
        width: 60%;
        margin: 0 auto;
    }
    .accordians-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
    }
    .accordians-inner h4 {
        display: flex;
        color: #197bbd;
        font-weight: 400;
        font-size: 14px;
    }
    .accordians-inner h4 span {
        padding-right: 10px;
        font-size: 22px;
    }
    .total-recap {
        font-size: 20px;
    }
    .main-header a {
        display: none;
    }
    .wrap .main {
        padding-top: 0px;
    }
    .main-header {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .header .wrap {
        width: 80% !important;
    }
    .sidebar-header {
        width: 80%;
    }
    .wrap-content .main {
        width: 80%;
    }
}

@media screen and (max-width: 568px) {
    .header .wrap {
        width: 90% !important;
    }
    .sidebar-header {
        width: 90%;
    }
    .wrap-content .main {
        width: 90%;
    }
    .content-body {
        flex-wrap: wrap;
    }
    .btn {
        height: 12.5vw;
        font-size: 2.778vw;
        margin-top: 11.111vw;
        line-height: 12.5vw;
        letter-spacing: 0.358vw;
        padding: 0 13.889vw 0 calc(100vw / 12 * 1);
    }
}
</style>