<template>
    <div class="container">
        <div class="container">
            <div class="row justify-content-md-center mt-5">
                <div class="col-12">
                <div class="card">
                    <div class="card-body">
                    <h5 class="card-title">Test orders for Inline Cart</h5>
                    <p class="card-text">
                        Setting test mode in order to use <b>card-based payment methods</b> using test cards and test cardholder names
                    </p>
                    <a class="btn btn-success" id="buy-button">Buy now!</a>
                    </div>
                </div>
                </div>
                <!-- <a @click="buy( src, libName, config)" class="avangate_button" product-code="L68N58GUZN" product-quantity="1">Buy now!</a> -->
            </div>
        </div>
        <!-- <div class="row justify-content-md-center mt-5">
            <div class="col-12">
            <div class="card">
                <div class="card-body">                    
                    <form type="post" id="payment-form">
                        <div class="form-group">
                            <input type="text" id="name" class="field form-control" placeholder="Name">
                        </div>

                        <div id="card-element">
                        A TCO IFRAME will be inserted here.
                        </div>

                        <button class="btn btn-primary" type="submit">Generate token</button>
                    </form>
                </div>
            </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data(){
        return{
            data: {
                accessTolken: '',
            },
            src:'https://secure.avangate.com/checkout/client/twoCoInlineCart.js',
            libName:'TwoCoInlineCart',
            config:{
                app:{
                iframeLoad: "checkout",
                merchant: "250749073669"
                },
                cart:{
                customization: "inline",
                host: "https://secure.2checkout.com"
                }
            },
            document:'',
        }
    },
    mounted(){
        window.document.getElementById('buy-button').addEventListener('click', () => {
            var products = []
            for (var ind in this.$store.state.cart.items) {
                products.push({
                    "name": this.$store.state.cart.items[ind].name,
                    "type": "product",
                    "quantity": this.$store.state.cart.items[ind].product_quantity,
                    "price": this.$store.state.cart.items[ind].price,
                    "tangible": true
                })
            }
            var items = {
                "merchant": "250749073669",
                "dynamic": "1",
                "currency": "PKR",
                "products": products,
                "return-method": {
                    "type": "redirect",
                    "url": "http://localhost:8080/#/checkout/submitOrderCheckout"
                }
            }
            axios.post('http://localhost:3000/getSignature', {items:items})
            .then(r => {
                // console.log(r.data.signature)
                var signature = r.data.signature
                TwoCoInlineCart.setup.setMode('DYNAMIC');
                TwoCoInlineCart.cart.setCurrency('PKR');
                TwoCoInlineCart.products.removeAll();
                TwoCoInlineCart.products.addMany(products);
                TwoCoInlineCart.cart.setReturnMethod({
                    type: 'redirect',
                    url : 'http://localhost:8080/#/checkout/submitOrderCheckout'
                });
                TwoCoInlineCart.cart.setSignature(signature);
                TwoCoInlineCart.cart.checkout();
            })
        })
    },
    created(){
        this.data.accessTolken = ''
    },
    methods:{
        loadMethod(){
            window.addEventListener('load', () => {
            // Initialize the 2Pay.js client.
            let jsPaymentClient = new TwoPayClient('AVLRNG');
            
            // Create the component that will hold the card fields.
            let component = jsPaymentClient.components.create('card');
            
            // Mount the card fields component in the desired HTML tag. This is where the iframe will be located.
            component.mount('#card-element');

            // Handle form submission.
            document.getElementById('payment-form').addEventListener('submit', (event) => {
                event.preventDefault();
                
                // Extract the Name field value
                const billingDetails = {
                name: document.querySelector('#name').value
                };

                // Call the generate method using the component as the first parameter
                // and the billing details as the second one
                jsPaymentClient.tokens.generate(component, billingDetails).then((response) => {
                // console.log(response.token);
                this.data.accessTolken = response.token
                // axios.post('http://localhost:3000/payment', this.data)
                // .then(r => {
                //     console.log(r)
                // })
                }).catch((error) => {
                // console.error(error);
                });
            });
            });
        },
        buy( src, libName, config) {
            // console.log(123)
            this.document = window.document
            var script = document.createElement('script');
            script.src = src;
            script.async = true;
            var firstScriptElement = document.getElementsByTagName('script')[0];
            script.onload = function () {
                for (var namespace in config) {
                    if (config.hasOwnProperty(namespace)) {
                        window[libName].setup.setConfig(namespace, config[namespace]);
                    }
                }
                window[libName].register();
            };
            firstScriptElement.parentNode.insertBefore(script, firstScriptElement);
        },
    }
}
</script>

<style scoped>
.field{
    padding: 13px 10px;
    border-radius: 5px;
    width: 49%;
    border: 1px solid #d9d9d9;
    margin-bottom: 20px;
}
#card-element{
    padding: 2%;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 10px;
}
</style>