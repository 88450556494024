<template>
  <div>

  </div>
</template>

<script>
export default {
  methods:{
      initPage() {
        this.$router.push({ path: '/page/home' })
      }
  },
  mounted () {
     this.initPage();
  }
}
</script>

<style>

</style>