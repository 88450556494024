<template>
  <div class="checkout">
    <div class="header">
      <div class="wrap">
        <a href="#">Meethas</a>
      </div>
    </div>
    <div class="content">
      <div class="wrap">
        <div class="wrap-content">
          <div class="main">
            <header class="main-header">
              <a href="#"><span>Meethas</span></a>
              <div class="Breadcrumb">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"
                      data-tabtar="lgm-2"
                    >
                    <span>Information</span
                    ><i class="material-icons">keyboard_arrow_right</i>
                  </li>
                  <li class="breadcrumb-item"
                      data-tabtar="lgm-2"
                    >
                    <span>Shipping</span
                    ><i class="material-icons">keyboard_arrow_right</i>
                  </li>
                  <li class="breadcrumb-item"
                      data-tabtar="lgm-2"
                    >
                    <span>Payment</span>
                  </li>
                </ul>
              </div>
            </header>
            <div class="main-content">
              <router-view name="Checkout"/>
            </div>
          </div>
          <aside class="sidebar">
            <div class="sidebar-header">
              <div class="order-summary-toggle">
                <div class="order-summary-inner">
                  <div class="accordians-inner"> 
                    <h4 v-if="!siderShow"  @click="changeMe()"><span class="material-icons">shopping_cart</span> Show order summary <span class="material-icons">keyboard_arrow_down</span></h4>
                    <h4 v-if="siderShow" @click="hideMe()"><span class="material-icons">shopping_cart</span> Hide order summary <span class="material-icons">keyboard_arrow_up</span></h4>      
                    <p v-if="data && data.calculate && data.calculate.total && currency == 'PKR'" class="total-recap">{{formatMoney(data.calculate.total)}} {{currency}}</p>           
                    <p v-if="data && data.calculate && data.calculate.total && currency == 'USD'" class="total-recap">{{currency}} {{convertCurrency(data.calculate.total)}}/- </p>           
                  </div>
                </div>
                <div  class="accordoians-content" v-if="siderShow">
                  <div class="order-summary">
                    <div v-for="(item,ind) in data.items" :key="ind" class="product-list">
                      <div class="product">
                        <div class="product-ls">
                          <div class="product-image">
                            <div class="product-imgBox">
                              <div class="product-quantity">{{item.product_quantity}}</div>
                              <img v-for="(image,i) in item.images" v-if="image.default == 'Yes'" :src="$store.state.file_url + image.image[0].src + '?alt=media'" alt="">
                            </div>
                          </div>
                          <div class="product-description">
                            <span class="product-description-name">{{item.name}}</span>
                            <span v-for="(variants,i) in item.selected_variants" :key="i" class="product-description-varient">{{variants}}</span>
                          </div>
                        </div>
                        <div class="product-price">
                          <span v-if="currency == 'PKR'">{{formatMoney(item.price)}}/-</span>
                          <span v-if="currency == 'USD'">{{convertCurrency(item.price)}}/-</span>
                        </div>
                      </div>
                    </div>
                    <div class="total-lines">
                      <div class="total-line-table">
                        <div class="total-line-subtotal">
                          <div class="total-line__name">
                            Subtotal
                          </div>
                          <div v-if="data && data.calculate && data.calculate.sub_total && currency == 'PKR'" class="total-line__price">
                            {{formatMoney(data.calculate.sub_total)}} {{currency}}
                          </div>
                          <div v-if="data && data.calculate && data.calculate.sub_total && currency == 'USD'" class="total-line__price">
                            {{currency}} {{convertCurrency(data.calculate.sub_total)}}/-
                          </div>
                        </div>
                        <div class="total-line-subtotal">
                          <div class="total-line__name">
                            Shipping
                          </div>
                          <!-- <div v-if="formatMoney(data.calculate.sub_total) < 1500" class="total-line__shipping">
                            150 PKR
                          </div> -->
                          <div class="total-line__shipping">
                            {{data.calculate.shipping}} {{currency}}
                          </div>
                        </div>
                        <div class="total-line-subtotal">
                          <div class="total-line__name">
                            Taxes
                          </div>
                          <div class="total-line__shipping">
                            {{data.calculate.taxes}} {{currency}}
                          </div>
                        </div>
                      </div>
                      <div class="total-line">
                        <div class="total-line-subtotal">
                          <div class="total-line__name">
                            Total
                          </div>
                          <div v-if="data && data.calculate && data.calculate.total && currency == 'PKR'" class="total-price">
                            {{formatMoney(data.calculate.total)}} {{currency}}
                          </div>
                          <div v-if="data && data.calculate && data.calculate.total && currency == 'USD'" class="total-price">
                            {{currency}} {{convertCurrency(data.calculate.total)}}/-
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar-content">
              <div class="order-summary">
                    <div v-for="(item,ind) in data.items" :key="ind" class="product-list">
                      <div class="product">
                        <div class="product-ls">
                          <div class="product-image">
                            <div class="product-imgBox">
                              <div class="product-quantity">{{item.product_quantity}}</div>
                              <img v-for="(image,i) in item.images" :key="i" v-if="image.default == 'Yes'" :src="$store.state.file_url + image.image[0].src + '?alt=media'" alt="">
                            </div>
                          </div>
                          <div class="product-description">
                            <span class="product-description-name">{{item.name}}</span>
                            <span v-for="(variants,i) in item.selected_variants" :key="i" class="product-description-varient">{{variants}}</span>
                          </div>
                        </div>
                        <div class="product-price">
                          <span v-if="currency == 'PKR'">{{formatMoney(item.price)}}/-</span>
                          <span v-if="currency == 'USD'">{{currency}} {{convertCurrency(item.price)}}/-</span>
                        </div>
                      </div>
                    </div>
                    <div class="total-lines">
                      <div class="total-line-table">
                        <div class="total-line-subtotal">
                          <div class="total-line__name">
                            Subtotal
                          </div>
                          <div v-if="data && data.calculate && data.calculate.sub_total && currency == 'PKR'" class="total-line__price">
                            {{formatMoney(data.calculate.sub_total)}} {{currency}}
                          </div>
                          <div v-if="data && data.calculate && data.calculate.sub_total && currency == 'USD'" class="total-line__price">
                            {{currency}} {{convertCurrency(data.calculate.sub_total)}}/-
                          </div>
                        </div>
                        <div class="total-line-subtotal">
                          <div class="total-line__name">
                            Shipping
                          </div>
                          <div class="total-line__shipping" v-if="data.calculate.shipping != 0">
                            {{currency}} {{data.calculate.shipping}}/-
                          </div>
                          <div class="total-line__shipping" v-else>
                            free
                          </div>
                        </div>
                        <div class="total-line-subtotal">
                          <div class="total-line__name">
                            Taxes
                          </div>
                          <div class="total-line__shipping" v-if="data.calculate.tax != 0">
                            {{currency}} {{data.calculate.tax}}/-
                          </div>
                          <div class="total-line__shipping" v-else>
                            free
                          </div>
                        </div>
                      </div>
                      <div class="total-line">
                        <div class="total-line-subtotal">
                          <div class="total-line__name">
                            Total
                          </div>
                          <div v-if="data && data.calculate && data.calculate.total && currency == 'PKR'" class="total-price">
                            {{formatMoney(data.calculate.total)}} {{currency}}
                          </div>
                          <div v-if="data && data.calculate && data.calculate.total && currency == 'USD'" class="total-price">
                            {{currency}} {{convertCurrency(data.calculate.total)}}/-
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
    <div class="hideTempelate">
      <div id="template">
        <table style="width:90%; margin: 0 auto">
                <tr style="width:200px; margin:0 auto; text-align:center">
                    <td>
                        <img src="http://cdn.mcauto-images-production.sendgrid.net/bc71f91a00d83814/661c6d5b-5dbb-4e6b-8318-6a84224b101c/1501x1501.png" alt="" width="20%" style="margin-top:4%">
                    </td>
                </tr>
                <tr>
                    <td>
                        <h2 style="text-align:center">
                            New Order Received
                        </h2>
                    </td>
                </tr>
                <tr>
                    <td>
                        <hr>
                        <h4 style="width:90%; text-align:left; margin:0 auto">Your order have been placed</h4>
                    </td>
                </tr>
                <tr>
                    <td>
                        <!-- <h4 style="width:90%; text-align:left; margin:0 auto">Order Id: TB 1203 at 12-Dec-2019 7:50PM</h4> -->
                        <hr>
                    </td>
                </tr>
        </table>
        <table style="width:85%; margin-left: 10%;">
            <tr>
                <td>
                    <table style="width:100%; text-align:center">
                        <tr style="width:200px; height:20px">
                            <td style="width:100px; height:20px">
                                <h5 style="margin-bottom: 0; margin-top:0; text-align: left;">Name : </h5>
                            </td>
                            <td style="width:100px; height:20px">
                                <p style="margin-bottom:0; margin-top:0; text-align: left;"> {{userInfo.first_name}} {{userInfo.last_name}}</p>
                            </td>
                        </tr>
                        <tr style="width:200px; height:20px">
                            <td style="width:100px; height:20px">
                                <h5 style="margin-bottom: 0; margin-top:0; text-align: left;">Email : </h5>
                            </td>
                            <td style="width:100px; height:20px">
                                <p style="margin-bottom:0; margin-top:0; text-align: left;"> {{userInfo.email}}</p>
                            </td>
                        </tr>
                        <tr style="width:200px">
                            <td style="width:100px">
                                <h5 style="margin-bottom: 0; margin-top:0; text-align: left;">Phone No : </h5>
                            </td>
                            <td style="width:100px">
                            <p style="margin-bottom:0; margin-top:0; text-align: left;"> {{userInfo.phone}}</p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <table style="width:40%; margin-left:10%;">
            <tr style="width:100%">
                <td style="width:100%">
                    <hr style="width:100%">
                </td>
            </tr>
            <tr>
                <td>
                    <!-- <p style="text-align:left; margin-top:0; margin-bottom:0"> Payment Method : {{data.payment.method }}</p> -->
                    <p style="text-align:left; margin-top:0; margin-bottom:0"> Sub Total: {{data.calculate.sub_total}}</p>
                    <p style="text-align:left; margin-top:0; margin-bottom:0"> Shipping: {{data.calculate.shipping}}</p>
                    <p style="text-align:left; margin-top:0; margin-bottom:0"> Tax: {{data.calculate.tax}}</p>
                    <p style="text-align:left; margin-top:0; margin-bottom:0"> Total: {{data.calculate.total}}</p>
                </td>
            </tr>                           
        </table>
        <table v-for="(items, ind) in data.items" :key="ind" style="width:65%; margin-left:10%;">
            <tr>
                <td>
                    <hr>
                </td>
            </tr>
            <tr>
                <td v-for="(images , ind) in items.images" :key="ind">
                    <img v-if="images.default == 'Yes'" :src=" $store.state.file_url + images.image[0].src + '?alt=media'" alt="" width="90%">
                </td>
                <td style="width:60%">
                    <!-- <h4 style="text-align:left; margin-top:0; margin-bottom:0">{{trimName(items.collections[0].slug)}}</h4> -->
                    <p style="font-weight:bold; text-align:left; margin-top:0; margin-bottom:0">Product Name: {{items.name}}</p>
                    <p style="text-align:left; margin-top:0; margin-bottom:0"> PRICE : {{items.price}}</p>
                    <p style="text-align:left; margin-top:0; margin-bottom:0"> QUANTITY : {{items.product_quantity}}</p>
                    <h1></h1>
                </td>
                <td>

                </td>
            </tr>                           
        </table>
        <table style="width:90%; margin:auto;">
                <tr style="width:100%;">
                    <td style="width:100%;">
                        <hr>
                    </td>
                </tr>
                <tr style="width:100%; text-align:center">
                    <td style="width:100%;">
                        <h3>Connect</h3>
                    </td>
                </tr>
                <tr style="width:100%; text-align:center">
                    <td style="width:50%;">
                        <!-- <a href="https://www.youtube.com/channel/UCjPhkd6roc1sUjR7iE7AAUA" style=""><img src="https://elasticbeanstalk-ap-south-1-905351406809.s3.ap-south-1.amazonaws.com/taana-baana/email-template-assets/twitter.png" alt="" width="5%"></a> -->
                        <a href="https://www.facebook.com/meethasyk" style=""><img src="https://virtual-eye.s3.eu-central-1.amazonaws.com/facebook.png" alt="" width="5%"></a>
                        <a href="https://www.instagram.com/meethasyk/?hl=en" style=""><img src="https://virtual-eye.s3.eu-central-1.amazonaws.com/instagram.png" alt="" width="5%"></a>
                    </td>
                </tr>
                <tr style="width:100%;">
                    <td>
                        <p style="color:rgb(138, 134, 134); font-size:14px; text-align:center">Contact us: Send us an email at info@virtualeye.online (customer care : Mon-Fri, (10:00 am - 6:00 pm PSt ) ) </p>
                    </td>
                </tr>
                <tr style="width:100%;">
                    <td style="width:100%;">
                        <hr>
                    </td>
                </tr>
        </table>        
      </div>
    </div>

  </div>
</template>
<script>
export default {
    data(){
        return{
            data:{},
            userInfo:{},
            siderShow: false,
            currency: ''
        }
    },
    created(){
            this.$store.dispatch('Store')
            this.data = this.$store.state.cart
            this.userInfo = this.$store.state.customer
            if(localStorage.getItem('currency')){
                this.currency = JSON.parse(localStorage.getItem('currency')) 
            }
            // console.log(this.data)
    },
    methods:{
        changeMe(){
            this.siderShow = true
        },
        hideMe(){
        this.siderShow = false
        },
        formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                // console.log(e)
            }
        },
        trimName(name) {
            // console.log(name)
            if (name)
            return name
            .replace(new RegExp("_", "g"), " ")
            .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
            .toUpperCase();
        },
        convertCurrency(amount) {
            var price = false
            var c = false
            if(localStorage.getItem('currencyValue')){
                c = JSON.parse(localStorage.getItem('currencyValue'))
            }
            price =  Math.round(amount/c)
            return price
        },

    }
}
</script>
<style scoped>
.checkout{
    margin-top: 12%;
    margin-bottom: 100px;
}
.checkout .header {
    display: none;
}

.checkout .home {
    background-image: linear-gradient(to right, white 60%, #fafafa 40%);
    width: 95%;
    margin: 30px auto;
}

.checkout .content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
}

.checkout .content .wrap {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 78.57rem;
    /* padding: 0 5%; */
    margin: 0 auto;
    flex: 1 0 auto;
}

.checkout .wrap .main {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    /* padding-left: 5%; */
    width: 60%;
    padding-right: 6%;
    float: left;
    padding-top: 2%;
}

.checkout .wrap-content {
    padding: 0 5%;
    display: flex;
    width: 100%;
}

.checkout .main-header {
    padding-bottom: 16px;
}

.checkout .main-header a span {
    color: #333333;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Saol Display';
}

.checkout .breadcrumb {
    display: flex;
    margin: 1em 0;
    padding: 0;
    list-style: none;
}

.checkout .breadcrumb li {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    /* font-weight: 500; */
}

.checkout .breadcrumb li.current {
    font-weight: 600;
}

.checkout .breadcrumb-item i {
    font-size: 16px;
    margin: 0 5px;
}

.checkout .Breadcrumb p {
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 25px;
}

.checkout .Breadcrumb h2 {
    margin-top: 0;
    font-weight: 400;
}

.checkout .main .main-content {
    padding-bottom: 50px;
    min-height: 68vh;
}

.checkout .main .section-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px;
    color: #333333;
}

.checkout .main .section-header h2 {
    font-size: 16px;
    margin: 0;
    font-weight: 400;
}

.checkout .main .section-header p {
    margin: 0;
    font-size: 13px;
}

.checkout .main .section-header p a {
    color: #af9aa4
}

.checkout .main .section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkout .fieldset {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.checkout .fieldset label {
    font-size: 12px;
}

.checkout input[type="checkbox"] {
    border: 1px solid #d9d9d9;
}

.checkout input:focus {
    outline: none;
    border: 2px solid #af9aa4 !important;
}

.checkout .checkbox {
    border: 1px solid #d9d9d9;
    margin-right: 10px;
    width: 15px;
    height: 15px;
}

.checkout .main .section-content .field {
    padding: 13px 10px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #d9d9d9;
    font-size: 12px;
}

.checkout .select-feild {
    width: 50% !important;
}

.checkout .main .section-content .field-half {
    padding: 13px 10px;
    border-radius: 5px;
    width: 46%;
    border: 1px solid #d9d9d9;
}

.checkout .main .section-content .field-half:nth-child(1) {
    margin-right: 5px;
}

.checkout .main .section-content .field-half:nth-child(2) {
    margin-left: 5px;
}

.checkout .section-shipping-address {
    padding-top: 3em;
    /* margin-bottom: 24px !important */
}

.checkout .section-shipping-addres {}

.checkout .main-footer {
    padding: 1em 0;
    border-top: 1px solid #e6e6e6;
}

.checkout .main-footer .copyright-text {
    font-size: 0.8571428571em;
    color: #737373;
}

.checkout .section__header {
    margin-bottom: 24px;
}

.checkout .shipping-address {
    padding-top: 50px;
}

.checkout .footer-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}

.checkout .footer-btn a p {
    display: flex;
    font-size: 14px;
    color: #af9aa4;
}

.checkout .footer-btn p a {
    font-size: 14px;
    color: #af9aa4;
}

.checkout .footer-btn a p span {
    font-size: 20px;
}

.checkout .fotter-btn {
    float: right;
    margin-top: 10px;
}

.checkout .btn {
    background-color: #af9aa4;
    color: white;
    height: 3.75vw;
    color: #fff;
    font-weight: bold;
    font-size: 0.813vw;
    font-family: 'Aktiv Grotesk';
    position: relative;
    align-items: center;
    margin-top: 4.375vw;
    display: inline-flex;
    background-color: #ae97a2;
    padding: 0 3.125vw 0 4.688vw;
    line-height: 3.75vw;
    letter-spacing: 0.104vw;
    text-transform: uppercase;
    transition: background-color 0.4s ease-out;
}

.checkout .sidebar {
    width: 40%;
    padding-left: 4%;
    padding-right: 4%;
    background-position: left top;
    float: right;
    background: #fafafa;
    border-left: 1px solid #e1e1e1;
    padding-top: 50px;
}

.checkout .sidebar-header {
    display: none;
}

.checkout .product-list {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
}

.checkout .product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.checkout .product-ls {
    display: flex;
    align-items: center;
}

.checkout .product-image {
    width: 80px;
}

.checkout .product-imgBox {
    width: 55px;
    height: 75px;
    border-radius: 5px;
    border: none;
}

.checkout .product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    position: relative;
    top: -10px;
    /* border: 1x solid; */
}

.checkout .product-description {
    display: flex;
    flex-direction: column;
}

.checkout .product-description-name {
    font-size: 12px;
    font-weight: 700;
}

.checkout .product-description-varient {
    font-size: 12px;
    font-weight: 300;
}

.checkout .product-quantity {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(114, 114, 114, 0.9);
    text-align: center;
    font-size: 12px;
    z-index: 9;
    color: #fff;
    position: relative;
    margin-left: 47px;
    margin-top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout .product-price {
    font-size: 14px;
    font-weight: 600;
}

.checkout .total-lines {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 25px;
}

.checkout .total-line-table {
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.checkout .total-line-subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout .total-line__name {
    font-size: 13px;
}

.checkout .total-line__price {
    font-size: 14px;
    font-weight: 600;
}

.checkout .total-line__shipping {
    font-size: 12px;
}

.checkout .total-line-subtotal {
    padding-bottom: 5px;
}

.checkout .total-line {
    padding-top: 16px;
}

.checkout .total-price {
    font-size: 24px;
    font-weight: 500;
    color: #323232;
}

.checkout .user-image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    /* background: red; */
}

.checkout .user-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.checkout .user-info {
    margin-left: 12px;
}

.checkout .user-info p {
    margin: 0;
    font-size: 12px;
}

.checkout .user-info p a {
    margin: 0;
    color: #af9aa4;
}

.checkout .section-headerr {
    margin-bottom: 12px;
    color: #333333;
    padding-top: 3em;
}

.checkout .section-headerr h2 {
    margin-bottom: 12px;
    font-size: 18px;
    margin: 0;
    font-weight: 400;
}

.checkout .section-headerr p {
    font-size: 14px;
    margin-top: 5px;
}

.checkout .review-fieldset {
    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    padding: 0 15px;
    border-radius: 5px;
}

.checkout .review-block {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #d9d9d9;
}

.checkout .review-block:last-child {
    border-bottom: none
}

.checkout .review-block-inner {
    display: flex;
    align-items: center;
}

.checkout .review-block-label {
    width: 90px
}

.checkout .review-block-label,
.map-block-content,
.review-block-content {
    font-size: 14px;
}

.checkout .map {
    margin-bottom: 15px;
}

.checkout .review-block-switch {
    width: 35px;
}

.checkout .review-block-switch input {
    width: 20px;
    height: 20px;
}

.checkout .review-block-content span {
    font-weight: 700;
}

.checkout .map-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    /* border-bottom: 1px solid #d9d9d9; */
}

.checkout .map-block-content {
    padding-bottom: 10px;
    padding-left: 15px;
}

.checkout .map-block-content h2 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.checkout .map-block-content p {
    font-size: 14px;
    margin: 5px 0;
    color: #545454;
}

.checkout .map-block-body {
    width: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    color: #545454;
    justify-content: center;
    background: #fafafa;
}

.checkout .map-block-inner {
    display: flex;
    align-items: center;
}

.checkout .content-body {
    display: flex;
    width: 100%;
}

.checkout .content-body-section {
    width: 44%;
    padding: 10px 15px;
}

.checkout .content-block-content {
    margin-bottom: 20px;
}

.checkout .content-block-content h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.checkout .content-block-content p {
    font-size: 14px;
    margin: 5px 0;
}

.checkout .review-fieldset-body {
    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    /* padding: 0 15px;  */
    border-radius: 5px;
}

.checkout .review-fieldset-body .review-block {
    padding: 10px 15px;
}

.checkout .review-block-body {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    color: #545454;
    justify-content: center;
    background: #fafafa;
}

.checkout .review-block-body .review-block-content {
    width: 90%;
    text-align: center;
}

.checkout .review-block-body .review-block-content {
    width: 100%;
    padding: 10px;
}

.checkout .link--small {
    font-size: 12px;
    color: #af9aa4;
}

.checkout .link-small-free {
    font-size: 14px;
    color: #323232;
    font-weight: 600;
}

@media (max-width: 1224px) {
    .checkout .wrap {
        width: 90% !important;
    }
    .checkout .wrap-content {
        padding: 0;
    }
}

@media screen and (max-width: 999px) {
    .checkout .header {
        display: block;
        max-width: 100%;
        height: 60px;
        /* background-color: #fff; */
        /* box-shadow: 0px 10px 10px #e6e6e6; */
        display: flex;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .checkout .header .wrap {
        width: 60% !important;
        margin: 0 auto !important;
        padding: 20px 0 0;
    }
    .checkout .header .wrap a {
        color: #333333;
        font-size: 22px;
    }
    .checkout .home {
        background: #fff;
    }
    .checkout .content .wrap {
        width: 100% !important;
    }
    .checkout .wrap-content {
        flex-direction: column-reverse;
    }
    .checkout .sidebar {
        width: 100%;
    }
    .checkout .wrap .main {
        padding-right: 0;
        margin: 0 auto;
    }
    .checkout .wrap {
        margin: 0 !important;
    }
    .checkout .sidebar {
        padding: 0;
        border: none;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
    }
    .checkout .sidebar-header {
        display: block;
    }
    .checkout .sidebar-content {
        display: none;
    }
    .checkout .sidebar-header {
        width: 60%;
        margin: 0 auto;
    }
    .checkout .accordians-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        padding: 15px 0;
    }
    .checkout .accordians-inner h4 {
        display: flex;
        color: #af9aa4;
        font-weight: 400;
        font-size: 14px;
    }
    .checkout .accordians-inner h4 span {
        padding-right: 10px;
        font-size: 22px;
    }
    .checkout .total-recap {
        font-size: 16px;
    }
    .checkout .main-header a {
        display: none;
    }
    .checkout .wrap .main {
        padding-top: 0px;
    }
    .checkout .main-header {
        padding: 0;
    }
}

@media screen and (max-width: 568px) {
    .checkout .header .wrap {
        width: 90% !important;
    }
    .checkout .sidebar-header {
        width: 90%;
    }
    .checkout .wrap-content .main {
        width: 90%;
    }
    .checkout .content-body {
        flex-wrap: wrap;
    }
    .checkout{
        margin-top: 25%;
    }
    .checkout .btn {
        background-color: #af9aa4;
        color: white;
        font-weight: 600;
        padding: 20px 25px;
        text-align: center;
        text-transform: uppercase;
        height: 12.5vw;
        font-size: 2.778vw;
        margin-top: 8.111vw;
        line-height: 12.5vw;
        letter-spacing: 0.358vw;
        padding: 0 13.889vw 0 calc(100vw / 12 * 1);
    }
}
.checkout .hideTempelate{
  position: fixed;
  top: 0;
  left: 150%;
}

</style>