import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import styles from './style.css'
import VCalendar from 'v-calendar';

Vue.config.productionTip = false

import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
    id: 'UA-154388335-1',
    ecommerce: {
        enabled: false,
        options: null,
        enhanced: false
    },
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

Vue.use(VCalendar, {
    componentPrefix: 'vc',
});