import Vue from 'vue'
import VueRouter from 'vue-router'
import Splash from '../views/Splash.vue'
import Checkout from '../views/Checkout.vue'
import Information from '../views/information.vue'
import Shipping from '../views/shipping.vue'
import Payment from '../views/payment.vue'
import paymentMethod from '../views/paymentMethod.vue'
import jazzCash from '../views/jazzCash.vue'
import submitOrderCheckout from '../views/submitOrderCheckout'
import cartDetail from '../views/cartDetail.vue'
import orderConfirm from '../views/orderConfirm.vue'
import myAccount from '../views/myAccount.vue'
import termCondition from '../views/termCondition.vue'


Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'splash',
        component: () =>
            import ("../views/Splash.vue")
    },
    {
        path: "/page/:route",
        name: "Home",
        component: () =>
            import ("../views/Home.vue")
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/About.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () =>
            import ('../views/Contact.vue')
    },
    {
        path: '/collection',
        name: 'collection',
        component: () =>
            import ('../views/Collection.vue')
    },
    {
        path: '/collection/:slug',
        name: 'collection',
        component: () =>
            import ('../views/Collection.vue')
    },
    {
        path: '/collection/:slug/:subCol',
        name: 'collection',
        component: () =>
            import ('../views/Collection.vue')
    },
    {
        path: '/productdetail/:slug/:id',
        name: 'productdetail',
        component: () =>
            import ('../views/productDetail.vue')
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout,
        children: [{
            path: '/checkout',
            name: 'information',
            components: {
                Checkout: Information
            }
        }, {
            path: '/checkout/shipping',
            name: 'shipping',
            components: {
                Checkout: Shipping
            }
        }, {
            path: '/checkout/payment',
            name: 'payment',
            components: {
                Checkout: Payment
            }
        },{
            path: '/checkout/payment/:id',
            name: 'payment',
            components: {
                Checkout: Payment
            }
        }, {
            path: '/checkout/paymentMethod',
            name: 'paymentMethod',
            components: {
                Checkout: paymentMethod
            }
        }, {
            path: '/checkout/jazzCash',
            name: 'jazzCash',
            components: {
                Checkout: jazzCash
            }
        }, {
            path: '/checkout/submitOrderCheckout',
            name: 'submitOrderCheckout',
            components: {
                Checkout: submitOrderCheckout
            }
        }]
    },
    {
        path: '/cartdetail',
        name: 'cartDetail',
        component: cartDetail
    },
    {
        path: '/checkout/orderconfirm',
        name: 'orderconfirm',
        component: orderConfirm
    },
    {
        path: '/myaccount',
        name: 'myAccount',
        component: myAccount
    },
    {
        path: '/account',
        name: 'Account',
        component: () =>
            import ('../views/account.vue')
    },
    {
        path: '/sizeChart',
        name: 'sizeChart',
        component: () =>
            import ('../views/sizeChart.vue')
    },
    {
        path: '/termcondition',
        name: 'termCondition',
        component: termCondition
    },
    {
        path: '/content/:pageName',
        name: 'content',
        component: () =>
            import ('../views/staticContent.vue')
    },
    {
        path: '/book_an_appointment',
        name: 'Appointment',
        component: () =>
            import ('../views/Appointment.vue')
    },
]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        // setTimeout(() => {
        return { x: 0, y: 0 }
        // },1000)
    }
})

export default router