<template>
    <div class="container mt100">
        <div class="heading">
            <h2>Your Cart</h2>
        </div>
        <div class="huge-9">
            <div class="cart-list__wrapper" v-if="data.items.length">
                <div class="cart-header">
                    <div class="cart-list__img"><b>Image</b></div>
                    <div class="cart-item__info">
                        <b class="cart-list__name">Product Name</b>
                        <b class="product-price">Price</b>
                        <b class="product-qty">Quantity</b>
                    </div>
                    <div class="cart-actions  text-center"><b>Action</b></div>
                </div>
                <div v-for="(items, index) in data.items" :key="index" class="cart-list">
                    <div class="cart-item">
                        <div v-for="(img,i) in items.images" :key="i" v-if="img.default == 'Yes'" class="cart-list__img">
                            <router-link :key="index" :to="{path:'/collection/cartetail/featured/'+items.id}" class="">
                                <img class="cart__image" :src="$store.state.file_url + img.image[0].src + '?alt=media'">
                            </router-link>
                        </div>
                        <div class="cart-item__info">
                            <div class="cart-list__name">
                                <h5 class="product-name">
                                    <a >{{items.name}}</a>
                                    <div class="cart-product__options">
                                        <i v-for="(variants,ind) in items.selected_variants" :key="ind">{{variants + ', '}}</i>
                                    </div>
                                </h5>
                            </div>
                            <div class="product-price">
                                <span class="current-price" v-if="currency == 'PKR'">
                                    {{formatMoney(items.price)}} {{currency}}
                                </span>
                                <span class="current-price" v-if="currency == 'USD'">
                                    {{currency}} {{convertCurrency(items.price)}}/- 
                                </span>
                            </div>
                            <div class="product-qty cart-product-qty">
                                <!-- <div class="increase">+</div> -->
                                <p class="text-center"> {{items.product_quantity}}</p>
                                <!-- <div class="reduce">-</div> -->
                            </div>
                        </div>
                        <div class="cart-actions">
                            <!-- <a href="#" class="edit"><span class="material-icons">create</span></a> -->
                            <a @click="removeCart(items)" class="remove"><span class="material-icons">delete_outline</span></a>
                        </div>
                    </div>
                </div>
                <div class="cart-footer mt50">
                    <div class="cart-footer-Info">
                        <div class="cart-pricing">
                            <h4 class="title">Subtotal: </h4>
                            <p class="cart-subtotal" v-if="currency == 'PKR'">
                                {{currency}} {{formatMoney(data.calculate.sub_total)}}
                            </p>
                            <p class="cart-subtotal" v-if="currency == 'USD'">
                                {{currency}} {{convertCurrency(data.calculate.sub_total)}}/-
                            </p>
                        </div>
                        <div class="cart-pricing">
                            <h4 class="title">Shipping: </h4>
                            <p class="cart-subtotal">
                               On checkout
                            </p>
                        </div>
                        <div class="cart-pricing">
                            <h4 class="title">Total: </h4>
                            <p class="cart-subtotal" v-if="currency == 'PKR'">
                                {{currency}} {{formatMoney(data.calculate.sub_total)}}
                            </p>
                            <p class="cart-subtotal" v-if="currency == 'USD'">
                                {{currency}} {{convertCurrency(data.calculate.sub_total)}}/-
                            </p>
                        </div>
                    </div>
                </div>
                <div class="buttons-container mt30 mb50">
                    <a href="/" class="btn btn-default">Continue Shopping</a>
                    <button @click="changeRoute('/checkout')" type="submit" class="btn">Checkout</button>
                </div>
            </div>
            <div class="productListGrid" v-else>
                <div class="section3">
                    <img src="../svg/empty-cart-2.png" alt="" width="30%">
                    <h2>Your Shopping Bag is Empty</h2>
                    <p>Looks like you have no item in your Shopping bag.</p>
                    <a class="btn" href="/#/">Go To Home</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  components: {},
  data(){
    return {
        data: {},
        product: {
            product_quantity: 1
        },
        currency: ''
    }
  },
    watch:{
        "$route.params": {
        handler(newValue, preValue) {
            this.init();
        },
        immediate: true
        }
    },

  created(){
    this.init()
    if(localStorage.getItem('currency')){
        this.currency = JSON.parse(localStorage.getItem('currency')) 
    }
  },
  methods: {
    changeRoute(path){
        document.getElementById('cartContainer').classList.remove('openCart'); 
        this.$router.push({path: path})
    },
    init(){
        this.$store.dispatch('Store')
        this.data = this.$store.state.cart
        // console.log(this.data)
    },
    removeCart(product){
        var params = { action: "remove", product , product };
        this.$store.dispatch("Cart", params);
        this.$store.dispatch('Store')
        this.data = this.$store.state.cart
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            // console.log(e)
        }
    },
    convertCurrency(amount) {
        var price = false
        var c = false
        if(localStorage.getItem('currencyValue')){
            c = JSON.parse(localStorage.getItem('currencyValue'))
        }
        price =  Math.round(amount/c)
        return price
    },

    closeCart(){
        document.getElementById('cartContainer').classList.remove('openCart'); 
    },
  },
}
</script>

<style scoped>

</style>
<style scoped>
.container{
    /* margin-top: 15%; */
    background: #f3f2f5;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.heading {
    margin-bottom: 20px;
}
.heading h2{
    text-align: center;
    font-weight: 600;
}
.huge-9 {
    max-width: 75%;
    /* margin: auto; */
}
.text-center {
    text-align: center;
}
.cart-header {
    padding: 20px 10px;
    border: 1px solid #efefef;
    border-bottom: 0;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    color:  #333333;
}
.cart-list__wrapper .cart-list__img {
    width: 150px;
    min-width: 150px;
    margin-left: 0;
    margin-right: 20px;
}
.cart-list__wrapper .cart-list__name{
    max-width: 200px;
    width: 200px;
}
.cart-header>div, .cart-item__info>div {
    margin: 0 20px;
}
.cart-list__wrapper .product-price {
    width: 150px;
    min-width: 150px;
    /* text-align: center; */
}
.cart-product__options{
    font-size: 14px;
    display: flex;
    padding: 5px 0;
}
.cart-list__wrapper .product-qty {
    width: 100px;
    padding: 5px 0;
}
.cart-list__wrapper .cart-actions {
    width: 130px;
    min-width: 130px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-list {
    /* padding: 0 30px; */
    border: 1px solid #efefef;
    border-spacing: 0;
    width: 100%;
}
.cart-list .cart-item {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
}
.cart-list__wrapper .cart-list__img {
    width: 100px;
    min-width: 85px;
    margin-left: 20px;
    margin-right: 20px;
}
.cart-list__wrapper .cart-list__img img {
    max-width: 100%;
}
.cart-item__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.product-name {
    color: #434343;
    font-weight: 400;
    letter-spacing: 0;
    font-size: 18px;
}
.product-name a{
    color: #434343;
    padding: 5px 0;
}
.cart-list__wrapper .product-price {
    width: 150px;
    min-width: 150px;
    padding: 5px 0;
}
.current-price {
    color: #af9aa4;
    font-size: 18px;
    font-weight: 700;
}
.cart-list__wrapper .qty-control {
    min-width: 100px;
    padding: 0;
}
.cart-product-qty{
    /* border: 1px solid #eee; */
    /* height: 50px; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-right: 40px !important;
}
.reduce, .increase {
    width: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-list__wrapper .qty-control {
    min-width: 100px;
    height: 40px;
    padding: 0;
}
.quantity {
    padding: .6rem 2.8rem;
    width: 100%;
    background-color: #fff;
    font-size: 1.5rem;
    line-height: 2.3rem;
    outline: none;
}
.cart-list__wrapper .remove,
.cart-list__wrapper .edit {
    margin-left: 10px;
    color: #b1b1b1;
}
.cart-actions .remove span{
        color: #e00b0b;
}
.cart-actions .remove span:hover{
        color: #131313;
}
.cart-list__wrapper .cart-footer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 50px;
}
.cart-footer-Info{
    border: 1px solid #eee;
}
.cart-list__wrapper .cart-footer .cart-pricing{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 10px 15px;
}
.cart-list__wrapper .cart-footer .cart-pricing .title{
    max-width: 150px;
    width: 150px;
}
.cart-list__wrapper .cart-footer .cart-pricing .cart-subtotal{
    max-width: 150px;
    width: 150px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.buttons-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    margin-top: 30px;
    margin-bottom: 50px;
}
.buttons-container .btn{
    background-color: #af9aa4;
    height: 3.75vw;
    color: #fff;
    font-weight: bold;
    font-size: 0.813vw;
    font-family: 'Aktiv Grotesk';
    position: relative;
    align-items: center;
    margin-top: 4.375vw;
    display: inline-flex;
    background-color: #ae97a2;
    padding: 0 3.125vw;
    line-height: 3.75vw;
    letter-spacing: 0.104vw;
    text-transform: uppercase;
    transition: background-color 0.4s ease-out;

}
.buttons-container .btn.btn-default{
   background: #232323;
}
.btn:focus{
    outline: none;
}
.buttons-container .btn:hover{
    box-shadow: 0px 2px 4px #af9aa447;
}
@media (max-width: 992px){
    .cart-header{
        display: none;
    }
    .huge-9{
        max-width: 90%;
    }
}
@media (max-width: 768px){
    .huge-9{

    }
    .buttons-container .btn.btn-default{

    }
    .buttons-container .btn{
        text-align: center;
        text-transform: uppercase;
        height: 12.5vw;
        font-size: 2.778vw;
        margin-top: 8.111vw;
        line-height: 12.5vw;
        letter-spacing: 0.358vw;
        padding: 0 13.889vw 0 calc(100vw / 12 * 1);
    }
    .container{
        margin-top: 20%;
    }
}
.productListGrid .section3{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0px;
}
.productListGrid .section3 img{
    width: 30%;
}
.productListGrid .section3 h2,
.productListGrid .section3 p,
.productListGrid .section3 .btn{
    margin-top: 15px;
}
</style>