<template>
  <div id="khota" class="app" v-on:scroll="handleScroll">
    <headerr/>
    <router-view style="min-height:100vh"/>
    <newsletter v-if="shownewsletter"/>
    <preFotter v-if="showpreFotter"/>
    <fotter/>
    <a href="https://api.whatsapp.com/send?phone=+923214570635" class="float" target="_blank">
      <img style="width: 93%;position: relative;left: 1px;" src="https://i.pinimg.com/originals/91/9d/f0/919df067a8fbd22ce7b6f401b7688b35.png">
    </a>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  components:{
    headerr: () => import('./components/Header'),
    fotter: () => import('./components/Fotter'),
    newsletter: () => import('./components/Newsletter'),
    preFotter: () => import('./components/preFotter'),
  },
  data() {
    return{
      shownewsletter:  false,
      showpreFotter: false,
      user:{},
      currency: ''
    }
    
  },
    watch: {
        "$route.params": {
        handler(newValue, preValue) {
          if (this.$route.path === '/wizard' || this.$route.path === '/collection' || this.$route.params.slug || this.$route.params.pageName || this.$route.path === '/checkout' || this.$route.path === '/checkout/shipping' || this.$route.path === '/checkout/payment' || this.$route.path === '/checkout/orderconfirm' || this.$route.path === '/page/about_us' || this.$route.path === '/contact' || this.$route.path === '/page/cartdetail' || this.$route.path === '/page/myaccount' || this.$route.path === '/termcondition') {
            if(this.$route.path === '/termcondition'){
              this.shownewsletter = false
              this.showpreFotter = true
            }
            else{
              this.showpreFotter = false
              this.shownewsletter = false
            }
          }
          else {
            this.shownewsletter = true
            this.showpreFotter = true
          } 
          setTimeout(F=>{
            document.getElementById('khota').scrollTop = 0;
          },2000)
        },
        immediate: true
        }
    },
    created(){
      axios.get('https://api64.ipify.org?format=json')
      .then(response => {
        axios.get('https://ipapi.co/'+response.data.ip+'/json')
        .then(res => {
          // console.log(res.data)
          localStorage.setItem('locationData', JSON.stringify(res.data))
        })
      })
      // this.convertCurrency('USD', 'PKR')
    },
    mounted(){
      this.$store.commit('store')
    },
    methods:{
      scrollTop(){
        document.getElementById('app').scrollTop(0);
      },
      handleScroll(e){
        if (e.srcElement.scrollTop > 100) {
            if(document.getElementById('menu'))
            document.getElementById('menu').classList.add("displayNone");
        } else {
            if(document.getElementById('menu'))
            document.getElementById('menu').classList.remove("displayNone");
        }
      },
      convertCurrency(fromCurrency, toCurrency) {
          var query = fromCurrency + '_' + toCurrency;
          var price = 0
          axios.post( this.$store.state.api + 'exchange_rate/_search')
          .then(response => {
              var result = response.data.hits.hits[0]._source.dollar
              // console.log(result, 'res')
              // var c =  false
              // for(var ind in result){
              //   c = result[ind];
              // }
              price =  Math.round(result)
              localStorage.setItem('currencyValue', JSON.stringify(price))
              // console.log(price)
          })
      }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 580px){
  .app{
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }
}
.float {
    position: fixed;
    width: 80px;
    height: 80px;
    bottom: -9px;
    left: 12px;
    background-color: transparent;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: none;
    z-index: 8;
}
</style>