import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        event: "meethas",
        //api: "https://expo-api.herokuapp.com/_/",
        api: "https://api-server-madness.web.app/_/",
        file_url: "https://storage.googleapis.com/digital-madness.appspot.com/",
        error: false,
        customer: false,
        vendors: {},
        favorite: [],
        currency: 'PKR',
        cart: {
            items: [],
            calculate: {
                sub_total: 0,
                shipping: 0,
                total: 0,
                count: 0,
                tax: 0
            },
            payment: {
                method: "COD"
            }
        },

    },
    mutations: {
        user(state, obj) {
            state.customer = obj
            localStorage.setItem(state.customer.table, JSON.stringify(state.customer));
        },
        store(state) {
            if (localStorage.getItem("cart")) {
                state.cart = JSON.parse(localStorage.getItem("cart"));
            }
            if (localStorage.getItem("users")) {
                state.customer = {}
                state.customer = JSON.parse(localStorage.getItem("users"));
            }
            if (localStorage.getItem("vendors")) {
                state.vendors = JSON.parse(localStorage.getItem("vendors"));
            }
            if (localStorage.getItem("favorite")) {
                state.favorite = JSON.parse(localStorage.getItem("favorite"));
            }
        },
        customer(state, customer) {
            state.customer = customer;
            localStorage.setItem("customer", JSON.stringify(state.customer));
        },
        error(state, value) {
            state.error = value.state
        },
        order_submit(state, res) {
            ////console.log(res);
            // state.order.id = res.id;
            state.cart = {
                items: [],
                calculate: {
                    sub_total: 0,
                    shipping: 0,
                    total: 0,
                    count: 0,
                    tax: 0
                },
                payment: {
                    method: "COD"
                }
            };
            localStorage.removeItem("cart");
        },
        favorite(state, itm) {
            // console.log(itm, 'favorite');
            var fount = false;
            for (var ind in state.favorite) {
                if (state.favorite[ind].id == itm.id) {
                    fount = ind;
                }
            }
            if (fount === false) {
                state.favorite.push(itm);
                var x = document.getElementById("showSnackbar");
                x.className = "show";
                setTimeout(function() {
                    x.className = x.className.replace("show", "");
                }, 3000);
            } else {
                state.favorite.splice(fount, 1);
                var x = document.getElementById("removeSnackbar");
                x.className = "show";
                setTimeout(function() {
                    x.className = x.className.replace("show", "");
                }, 3000);
            }

            localStorage.setItem("favorite", JSON.stringify(state.favorite));
        },
        removeFaveriote(state, itm) {
            state.favorite.splice(itm, 1);
            localStorage.setItem("favorite", JSON.stringify(state.favorite));
        },
        show(state, value) {
            if (value.prop == 'cart' && value.state == true) {
                document.getElementById('cartContainer').classList.add('openCart');
            }
        },
        cart(state, cart) {
            var cart = state.cart.items;
            var total = 0;
            var count = 0;
            for (var ind in cart) {
                var pr = cart[ind].price;
                total += parseInt(pr) * parseInt(cart[ind].product_quantity);
                count += parseInt(cart[ind].product_quantity);
            }
            if (total <= 1000) {
                var shipping = 199;
            } else {
                var shipping = 0;
            }
            state.cart.items = cart;
            state.cart.calculate.sub_total = total;
            state.cart.calculate.shipping = shipping;
            state.cart.calculate.total = state.cart.calculate.shipping + total;
            state.cart.calculate.count = count;
            state.order = {
                id: false
            };
            localStorage.setItem("cart", JSON.stringify(state.cart));
            state.cart = JSON.parse(localStorage.getItem("cart"));
        },
    },
    actions: {
        Store({ commit, state }) {
            commit("store");
        },
        SignUp({ commit, state }, obj, table) {
            obj.createdAt = moment();
            obj.name = obj.first_name + ' ' + obj.last_name
            var filters = [{
                    match_phrase: {
                        event: obj.event
                    }
                },
                {
                    match_phrase: {
                        email: obj.email
                    }
                }
            ]
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        state.api + obj.table + "/_search", {
                            from: 0,
                            size: 5000,
                            query: {
                                bool: {
                                    must: filters
                                }
                            }
                        }
                    ).then(response => {
                        if (response.data.hits.max_score == 0 || response.data.hits.max_score == null) {
                            axios
                                .post(
                                    state.api + obj.table + "/_doc",
                                    obj
                                ).then(response => {
                                    // console.log(response)
                                    obj.id = response.data._id
                                    obj.city = state.customer.city
                                    obj.country = state.customer.country
                                    commit("user", obj);
                                    if (obj.table == 'users') {
                                        axios.post(state.api + "send_email", {
                                            user: {
                                                email: obj.email
                                            },
                                            subject: "Thankyou for Signup",
                                            object: {
                                                name: obj.name
                                            },
                                            from: "info@virtualeye.online",
                                            template_id: "d-51cb1fa16079468ab1e7fbc70f4b91da"
                                        });
                                    } else if (obj.table == 'vendors') {
                                        axios.post(state.api + "send_email", {
                                            user: {
                                                email: obj.email
                                            },
                                            subject: "Thankyou for Signup",
                                            object: {
                                                name: obj.name
                                            },
                                            from: "info@virtualeye.online",
                                            template_id: "d-51cb1fa16079468ab1e7fbc70f4b91da"
                                        });
                                    } else {
                                        axios.post(state.api + "send_email", {
                                            user: {
                                                email: obj.email
                                            },
                                            subject: "Thankyou for Signup",
                                            object: {
                                                name: obj.name
                                            },

                                            from: "info@virtualeye.online",
                                            template_id: "d-51cb1fa16079468ab1e7fbc70f4b91da"
                                        });
                                    }
                                    commit("error", { prop: "error", state: false });
                                    resolve(obj)
                                });
                        } else {
                            commit("error", { prop: "error", state: true });
                            resolve(response)
                        }
                    })
            })
        },
        SignIn({ commit, state }, obj) {
            return new Promise((resolve, reject) => {
                var filters = [{
                        match_phrase: {
                            event: obj.event
                        }
                    },
                    {
                        match_phrase: {
                            email: obj.email
                        }
                    },
                    {
                        match_phrase: {
                            password: obj.password
                        }
                    }
                ]
                axios
                    .post(
                        state.api + obj.table + "/_search", {
                            from: 0,
                            size: 5000,
                            query: {
                                bool: {
                                    must: filters
                                }
                            }
                        }
                    ).then(response => {
                        if (response.data.hits.max_score == 0 || response.data.hits.max_score == null) {
                            commit('error', { prop: "error", state: true })
                            resolve(response)
                        } else {
                            obj = response.data.hits.hits[0]._source
                            obj.id = response.data.hits.hits[0]._id
                            obj.user_id = response.data.hits.hits[0]._id
                            obj.city = state.customer.city
                            obj.country = state.customer.country
                            commit('error', { prop: "error", state: false })
                            commit("user", obj);
                            resolve(response)
                        }
                    })
            })
        },
        ProductRead({ commit, state }, filters) {
            return new Promise((resolve, reject) => {
                axios.post(
                    state.api + "product/_search", {
                        from: 0,
                        size: 5000,
                        query: filters
                    }
                ).then(response => {
                    state.error = false
                    if (response.data.hits.max_score == 0 || response.data.hits.max_score == null) {
                        // console.log(response.data.hits, 'productRead')
                        resolve(response.data.hits.hits)
                        commit('error', { prop: "error", state: true })
                    } else {
                        var data = {}
                        for (var ind in response.data.hits.hits) {
                            data[ind] = response.data.hits.hits[ind]._source
                            data[ind].id = response.data.hits.hits[ind]._id
                                // console.log(data[ind], 111)
                        }
                        // console.log(data, 'productRead')
                        resolve(data)
                    }
                })
            })
        },
        CollectionRead({ commit, state }, filters) {
            return new Promise((resolve, reject) => {
                axios.post(
                    state.api + "collection/_search", {
                        from: 0,
                        size: 5000,
                        query: filters
                    }
                ).then(response => {
                    // console.log(response, 'colrre')
                    state.error = false
                    if (response.data.hits.max_score == 0 || response.data.hits.max_score == null) {
                        resolve(response.data.hits)
                        commit('error', { prop: "error", state: true })
                    } else {
                        var data = []
                        for (var ind in response.data.hits.hits) {
                            data[ind] = response.data.hits.hits[ind]._source
                            data[ind].id = response.data.hits.hits[ind]._id
                                // console.log(data[ind], 111)
                        }
                        // console.log(data, 'CollectionRead')
                        resolve(data)
                    }
                })
            })
        },
        OrderRead({ commit, state }, filters) {
            return new Promise((resolve, reject) => {
                axios.post(
                    state.api + "order/_search", {
                        from: 0,
                        size: 5000,
                        query: filters
                    }
                ).then(response => {
                    state.error = false
                    if (response.data.hits.max_score == 0 || response.data.hits.max_score == null) {
                        resolve(response.data.hits)
                        commit('error', { prop: "error", state: true })
                    } else {
                        var data = []
                        for (var ind in response.data.hits.hits) {
                            data[ind] = response.data.hits.hits[ind]._source
                            data[ind].id = response.data.hits.hits[ind]._id
                                // console.log(data[ind], 111)
                        }
                        resolve(data)
                    }
                })
            })
        },
        Favorite({ commit, state }, product) {
            //if (state.favorite[product.id]) {
            commit("favorite", product);
            //}
        },
        RemoveFaveriote({ commit, state }, product) {
            commit("removeFaveriote", product);
        },
        Cart({ commit, state }, obj) {
            var cart = []
            var cart_items_variants = {}
            var product_variants = {}
            var found = false
            cart = state.cart.items;
            if (obj.action == 'add') {
                for (var index in cart) {
                    if (cart[index].id == obj.product.id) {
                        Object.keys(cart[index].selected_variants).sort().forEach(function(key) {
                            cart_items_variants[key] = cart[index].selected_variants[key];
                        });
                        Object.keys(obj.product.selected_variants).sort().forEach(function(key) {
                            product_variants[key] = obj.product.selected_variants[key];
                        });
                        // console.log(cart_items_variants, product_variants)
                        if (JSON.stringify(product_variants) == JSON.stringify(cart_items_variants)) {
                            found = true
                                // console.log(found)
                            var data = cart[index].variants_classification
                            for (var i in data) {
                                var s = data[i].title
                                var match = s.split(',')
                                if (JSON.stringify(match) === JSON.stringify(Object.values(cart_items_variants))) {
                                    // console.log(data[i].quantity, 'found')
                                    if (
                                        parseInt(cart[index].product_quantity) +
                                        parseInt(obj.product.product_quantity) <=
                                        parseInt(data[i].quantity)
                                    ) {
                                        cart[index].product_quantity =
                                            parseInt(cart[index].product_quantity) +
                                            parseInt(obj.product.product_quantity);
                                        commit("show", { state: true, prop: "cart" });
                                    } else {
                                        alert("Quantity Exceed from available quantity");
                                    }
                                }
                            }
                        }
                    }
                }
                if (!found) {
                    // console.log(found)
                    cart.push(obj.product);
                    commit("show", { prop: "cart", state: true });
                }
            } else if (obj.action == 'remove') {
                for (var index in cart) {
                    if (cart[index].id == obj.product.id) {
                        Object.keys(cart[index].selected_variants).sort().forEach(function(key) {
                            cart_items_variants[key] = cart[index].selected_variants[key];
                        });
                        Object.keys(obj.product.selected_variants).sort().forEach(function(key) {
                            product_variants[key] = obj.product.selected_variants[key];
                        });
                        // console.log(cart_items_variants, product_variants)
                        if (JSON.stringify(product_variants) == JSON.stringify(cart_items_variants)) {
                            // console.log(true)
                            cart.splice(index, 1);
                        }
                    }
                }
            }
            commit("cart", cart);
        },
        Order({ commit, state }, obj) {
            var cart = []
            var coustomer = {}
            var data = {}
            if (localStorage.getItem('currency')) {
                data.currency = JSON.parse(localStorage.getItem('currency'))
            }
            coustomer = obj
            // coustomer.id = false
            console.log(obj, 'coustomer.id')
            data.createdAt = moment();
            data.event = state.event
            data.cart = state.cart.items;
            cart = state.cart.items;
            data.customer = coustomer;
            data.name = coustomer.first_name + " " + coustomer.last_name
            data.email = coustomer.email
            data.phone = coustomer.phone
            data.city = coustomer.city
            data.address = coustomer.address1
            data.payment = state.cart.payment;
            data.total = state.cart.calculate.total;
            data.shipping = state.cart.calculate.shipping;
            data.tax = state.cart.calculate.tax;
            data.sub_total = state.cart.calculate.sub_total;
            data.status = "Pending";
            console.log(data, 'order commit')
            var cart_items_variants = {}
            var products = []
            var count = 0
            // alert(1, 'none')
            axios
                .post(
                    state.api + "order/_doc",
                    data
                ).then(response => {
                    console.log(response, 'order insert')
                    var id = response.data._id
                    setTimeout(() => {
                        axios.post(state.api + "order/_search", {
                            from: 0,
                            size: 5000,
                            query: {
                                bool: {
                                    must: [{
                                        match_phrase: {
                                            _id: id
                                        }
                                    }]
                                }
                            }
                        }).then(response => {
                            console.log(response, 'order/_search')
                            if (response.data.hits.max_score) {
                                console.log(response.data.hits, 'esponse.data.hits.max_score')
                                var resp = response.data.hits.hits[0]._id
                                localStorage.setItem('orderid', JSON.stringify(resp))
                                console.log('template if')
                                commit("order_submit");
                                console.log('getElementById if')
                                axios.post(state.api + "send_email", {
                                    user: {
                                        email: coustomer.email
                                    },
                                    subject: "Meethas Order Confirmation",
                                    object: {
                                        content: document.getElementById("template").innerHTML,
                                        subject: 'Your order id is -' + resp
                                    },
                                    bcc: [
                                        "arwashahid052@gmail.com",
                                    ],
                                    from: "info@virtualeye.online",
                                    template_id: "d-0540dc67b7fd4d38a1b8a40a41dae503"
                                }).then(response => {
                                    console.log(response, 'send_email')
                                    ga('require', 'ecommerce'); //require
                                    ga('ecommerce:addTransaction', {
                                        // 'id': response.data._id,
                                        'affiliation': coustomer.name,
                                        'revenue': data.sub_total,
                                        'shipping': data.shipping,
                                        'tax': data.tax,
                                        'currency': data.currency // local currency code.
                                    });
                                    ga('ecommerce:send');
                                    commit("order_submit", response.data);
                                });
                            }
                        });
                    }, 500)
                });
        },
        getPages({ commit, state }, obj) {
            return new Promise((resolve, reject) => {
                var filters = [{
                        match_phrase: {
                            event: state.event
                        }
                    },
                    {
                        match_phrase: {
                            heading: obj.name
                        }
                    }
                ]
                axios
                    .post(
                        state.api + obj.table + "/_search", {
                            from: 0,
                            size: 5000,
                            query: {
                                bool: {
                                    must: filters
                                }
                            }
                        }
                    ).then(response => {
                        if (response.data.hits.max_score == 0 || response.data.hits.max_score == null) {
                            commit('error', { prop: "error", state: true })
                        } else {
                            var data = {}
                            data = response.data.hits.hits[0]._source
                            resolve(data)
                                // }
                        }
                    })
            })

        },
    },
    modules: {}
})