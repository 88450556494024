<template>
    <h4 style="margin-top:20px">Please Wait While You are Being Redirected...</h4>
</template>

<script>
export default {
    data(){
        return{
            userInfo: {}
        }
    },
    created(){
        this.userInfo = this.$store.state.customer
        this.data = this.$store.state.cart
        this.userInfo.payment_method = 'creditCard'
        this.userInfo.payment = 'Confirm'
        localStorage.setItem('users', JSON.stringify(this.userInfo))
        this.$store.dispatch('Order', this.userInfo)
        setTimeout(() => {
            this.userInfo = {}
            this.data = {}
            this.$router.push({path:'/checkout/orderconfirm'})
        }, 2500)
    },
    methods:{

    }
}
</script>

<style>

</style>